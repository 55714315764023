import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import  {Observable} from 'rxjs';
import { jobList } from './job-schedule/JobScheduleComponent.Model';
import { environment } from 'src/environments/environment';

const baseUrl=environment.baseUrl;
// var bodyParser=require('body-parser')
// var jsonparser=bodyParser.json()

@Injectable({
  providedIn: 'root'
})
export class AppServiceService {

  constructor( private http : HttpClient) { } 

  public getJobSummary(id:any,fromdate:any,todate:any):Observable<any>{
  
   // return this.http.get('http://localhost:3000/api/getJobSummary/'+id);
    return this.http.get(`${baseUrl}/getJobSummary/`+id+'/'+fromdate+'/'+todate);     
   
   }
   public getLAVolumeReport(schema:any):Observable<any>{
  
    //return this.http.get('http://localhost:3000/api/getFailedJobSummary/'+id); 
    return this.http.get(`${baseUrl}/getLAVolumeReport/`+schema);   
   
   }

   public getLAVolumeChart(schema:any):Observable<any>{
  
    //return this.http.get('http://localhost:3000/api/getFailedJobSummary/'+id); 
    return this.http.get(`${baseUrl}/getLAVolumeChart/`+schema);   
   
   }
   public getQCJobSummaryReport(schema:any,fromdate:any,todate:any):Observable<any>{  
    //return this.http.get('http://localhost:3000/api/getFailedJobSummary/'+id); 
    return this.http.get(`${baseUrl}/getQCJobSummaryReport/`+schema+'/'+fromdate+'/'+todate);  
   }

   public getQAJobSummaryReport(schema:any,fromdate:any,todate:any):Observable<any>{  
    //return this.http.get('http://localhost:3000/api/getFailedJobSummary/'+id); 
    return this.http.get(`${baseUrl}/getQAJobSummaryReport/`+schema+'/'+fromdate+'/'+todate);  
   }

   public getCaptureMethodApi(schema:any,fromdate:any,todate:any):Observable<any>{
    //return this.http.get('http://localhost:3000/api/getFailedJobSummary/'+id); 
    return this.http.get(`${baseUrl}/getCaptureMethod/`+schema+'/'+fromdate+'/'+todate);   
   }
   public getDigitiserAuditReport(schema:any,fromdate:any,todate:any):Observable<any>{  
    //return this.http.get('http://localhost:3000/api/getFailedJobSummary/'+id); 
    return this.http.get(`${baseUrl}/getDigitiserAuditReport/`+schema+'/'+fromdate+'/'+todate);  
   }
   public getDigitiserLiveReportNew(schema:any):Observable<any>{  
    //return this.http.get('http://localhost:3000/api/getFailedJobSummary/'+id); 
    return this.http.get(`${baseUrl}/getDigitiserLiveReportNew/`+schema);  
   }
   
   public getQCLiveReport(schema:any):Observable<any>{  
    //return this.http.get('http://localhost:3000/api/getFailedJobSummary/'+id); 
    return this.http.get(`${baseUrl}/getQCLiveReport/`+schema);  
   }
   public getQALiveReport(schema:any):Observable<any>{  
    //return this.http.get('http://localhost:3000/api/getFailedJobSummary/'+id); 
    return this.http.get(`${baseUrl}/getQALiveReport/`+schema);  
   }
   public getDigitiserAuditChart(schema:any,fromdate:any,todate:any):Observable<any>{  
    //return this.http.get('http://localhost:3000/api/getFailedJobSummary/'+id); 
    return this.http.get(`${baseUrl}/getDigitiserAuditChart/`+schema+'/'+fromdate+'/'+todate);  
   }
   
   public getStatusSummaryApi(schema:any,schemacode:any,fromdate:any,todate:any):Observable<any>{   
    return this.http.get(`${baseUrl}/getStatusSummaryReport/`+schema+'/'+schemacode+'/'+fromdate+'/'+todate);  
   }
   
   public getStatusSummaryChartApi(schema:any,schemacode:any,fromdate:any,todate:any):Observable<any>{   
    return this.http.get(`${baseUrl}/getStatusSummaryChart/`+schema+'/'+schemacode+'/'+fromdate+'/'+todate);  
   } 

  public getData(schema:any,useremail:any):Observable<any>{
return this.http.get(`${baseUrl}/getJobs/`+schema+'/'+useremail); 
 //return this.http.get(`http://localhost:3001/api/getJobs/`+schema+'/'+useremail); 

 }
 //Not using this service
 public getJobsinLoop(schema:any,useremail:any,index:any,range:any):Observable<any>{
  //return this.http.get(`${baseUrl}/getJobsinLoop/`+schema+'/'+useremail+`/`+index+'/'+range); 
   return this.http.get(`http://localhost:3001/api/getJobsinLoop/`+schema+`/`+useremail+`/`+index+`/`+range);  
   }
  //getTotalDataCount
  //Not using this service
  public getTotalDataCount(schema:any,useremail:any):Observable<any>{
    //return this.http.get(`${baseUrl}/getJobsinLoop/`+schema+'/'+useremail+`/`+index+'/'+range); 
     return this.http.get(`http://localhost:3001/api/getJobsinLoop/`+schema+`/`+useremail+`/0/0`);  
     }
 public getTQTJobs(id:any,useremail:any):Observable<any>{
  return this.http.get(`${baseUrl}/getTQTJobs/`+id+'/'+useremail); 
  // return this.http.get('http://localhost:3000/api/getOrders/'+id+'/'+useremail); 
  
   }
 
 public getRoleId(userid:any):Observable<any>{
  return this.http.get(`${baseUrl}/getRoleId/`+userid); 
  // return this.http.get('http://localhost:3000/api/getOrders/'+id+'/'+useremail); 
  
   }


 public getAllJobs():Observable<any>{
  
 return this.http.get('http://localhost:3000/api/getAllJobs');



//return this.http.get(`${baseUrl}/getOrders/`+id+'/'+useremail); 
//return this.http.get('http://localhost:3000/api/getOrders/'+id+'/'+useremail);  


}


 public getDatafilter(id:any):Observable<any>{
  
//return this.http.get(`${baseUrl}/getOrders/`+id+'/'+useremail); 
return this.http.get('http://localhost:3000/api/getOrdersfilter/'+id);  


}
  public getsandhyaapi():Observable<any>{  
   // return this.http.get(`https://llcmigration-clamp-pdq-rest.cyienteurope.com/api/llcs/1`);
   return this.http.get(`https://llcmigration-clamp-wat-rest.cyienteurope.com/api/getQCJobs`);
  }

  public getQCData(schema:any,useremail:any):Observable<any>{  
    return this.http.get(`${baseUrl}/getQCJobsId/`+schema+'/'+useremail);
   // return this.http.get('http://localhost:3000/api/getQCJobsId/'+id+'/'+useremail); 


  
  }
  public getQAData(schema:any,useremail:any):Observable<any>{  
   return this.http.get(`${baseUrl}/getQAJobsId/`+schema+'/'+useremail);
    //return this.http.get('http://localhost:3000/api/getQAJobsId/'+id+'/'+useremail); 
   
  }

  public getjobData(id:any):Observable<any>{
    const params = new HttpParams()
    .set('laid', id);
    return this.http.get(`${baseUrl}/getjobOrders`,{params});
  }

 
  public getUsers():Observable<any>{
    return this.http.get(`${baseUrl}/getUsers`);
  }
  public getClampUsers(appname:string,lacode:string):Observable<any>{
    return this.http.get(`${baseUrl}/getClampUsers/`+appname+`/`+lacode);
    //return this.http.get(`http://localhost:3001/api/getClampUsers/`+appname+`/`+lacode);
  }

  public getLocalauthorities():Observable<any>{
    return this.http.get(`${baseUrl}/getLocalauthorities`);
  }
  //getLAnamesbyUsermeailid
  public getLAnamesbyUsermeailid(emailid:string):Observable<any>{
    return this.http.get(`${baseUrl}/getLAnamesbyUsermeailid/`+emailid);
    //return this.http.get('http://localhost:3001/api/getLAnamesbyUsermeailid/'+emailid);
  }

  //Assign TQT Jobs
  public assignTQTJob(newjob: any)
  { 
   // let toAdd = JSON.stringify(newjob);    
    const httpOptions = {
      headers: new HttpHeaders({              
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE' 

       })
     };

    // return this.http.post('http://localhost:3000/api/assignJob',newjob,httpOptions);
      return this.http.post( `${baseUrl}/assignTQTJob`, newjob,httpOptions);
     
      
   }
 
  //Assign Job
  public assignJob(newjob:any,schema:any)
   { 
    // let toAdd = JSON.parse(newjob);    
     const httpOptions = {
       headers: new HttpHeaders({              
         'Access-Control-Allow-Origin': '*',
         'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE' 

        })
      };

     // return this.http.post('http://localhost:3000/api/assignJob',newjob,httpOptions);
      // return this.http.post( `${baseUrl}/assignJob`, newjob,httpOptions); // original one
     return this.http.post( `${baseUrl}/assignJob/`+schema, newjob,httpOptions);     
       
    }



    public assignQCJob (newjob:any,schema:any)
    {    
     const httpOptions = {
      headers: new HttpHeaders({              
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE' 

       })
     };
          return this.http.post(`${baseUrl}/assignQCJob/`+schema, newjob, httpOptions);
     }

     public assignQAJob (newjob:any,schema:any)
     { 
      const httpOptions = {
        headers: new HttpHeaders({              
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE' 
  
         })
       };
           return this.http.post(`${baseUrl}/assignQAJob/`+schema, newjob, httpOptions);
      }
     

    public deleteJob (newjob: any)
    { 
     
     // let toAdd = JSON.parse(newjob);
     
      
      const httpOptions = {
        headers: new HttpHeaders({
           'Content-Type': 'application/json'
          
        })
       };
          return this.http.post(`${baseUrl}/deleteJob`, newjob, httpOptions);
     }
 //bulk insert TQT Jobs
 public UploadFromExcelToDb (newjob: any)
 { 
  //let toAdd = JSON.stringify(newjob);
  console.log('upload new job',newjob);
  
  const httpOptions = {
    headers: new HttpHeaders({       
        'Content-Type': 'application/json', 
        'Authorization': 'my-auth-token',      
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,HEAD,PUT,PATCH,POST,DELETE' 

     })
   };
       return this.http.post(`${baseUrl}/UploadFromExcelToDb`, newjob, httpOptions);
       //return this.http.post('http://localhost:3000/api/postExcelData', toAdd, httpOptions);
  }


    //bulk insert
  public postnewJobQC (newjob: any)
  { 
   
    let toAdd = JSON.parse(newjob);
     //toAdd = toAdd.substring(1,toAdd.length-1);  
   // console.log('data is',toAdd);
    
    const httpOptions = {
      headers: new HttpHeaders({
         'Content-Type': 'application/json',
        'Authorization': 'my-auth-token'
      })
     };
        return this.http.post(`${baseUrl}/postExcelDataQC`, toAdd, httpOptions);
   }

   //QC Bulk

   public postnewJobQCBulk (newjob: any)
   { 
    
     let toAdd = JSON.parse(newjob);
      //toAdd = toAdd.substring(1,toAdd.length-1);  
    // console.log('data is',toAdd);
     
     const httpOptions = {
       headers: new HttpHeaders({
          'Content-Type': 'application/json',
         'Authorization': 'my-auth-token'
       })
      };
         return this.http.post(`${baseUrl}/postExcelDataQCBulk`, toAdd, httpOptions);
    }

     //QA Bulk

   public postnewJobQABulk (newjob: any)
   { 
    
     let toAdd = JSON.parse(newjob);   
     const httpOptions = {
       headers: new HttpHeaders({
          'Content-Type': 'application/json',
         'Authorization': 'my-auth-token'
       })
      };
         return this.http.post(`${baseUrl}/postExcelDataQABulk`, toAdd, httpOptions);
        
        // return this.http.post('http://localhost:3000/api/postExcelDataQABulk', toAdd, httpOptions);

    }

  
//single job
    public postJob (newjob: any)
    {   
     
      const httpOptions = {
        headers: new HttpHeaders({
           'Content-Type': 'application/json'          
        })
       };
          return this.http.post(`${baseUrl}/createJob`, newjob, httpOptions);
     }
  
     public updateJob (newjob: any)
     {   
      
       const httpOptions = {
         headers: new HttpHeaders({
            'Content-Type': 'application/json'          
         })
        };
           return this.http.post(`${baseUrl}/updateJob`, newjob, httpOptions);
      }

      //get all clamp users list and user roles to assign
      public getAllClampUsersList():Observable<any>{
        //return this.http.get(`http://localhost:3001/api/getallclampusers_roles`);         
        return this.http.get(`${baseUrl}/getallclampusers_roles`);         
         }
         //get QRT LA Reports 
      public getQRTLAReports(ladetails: any):Observable<any>{
        //return this.http.post(`http://localhost:3001/api/getQRTLA_reports_LA`,ladetails);         
        return this.http.post(`${baseUrl}/getQRTLA_reports_LA`,ladetails);  
              
         }
      public sendEmailtoNewUser (userdata: any){   
               return this.http.post(`${baseUrl}/sendEmailToInviteUser`, userdata);
          }
      public deleteClampUser (userdata: any)
      {  
        return this.http.post(`${baseUrl}/DeleteClampUser`, userdata);
        //return this.http.post(`http://localhost:3001/api/DeleteClampUser`, userdata);
      }

   
}
