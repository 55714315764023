<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<button (click)="getSelectedRowData()">Get Selected Nodes</button>
<ag-grid-angular 
    style="width: 1300px; height: 900px;"
    class="ag-theme-alpine"
   
    #agGrid
    style="width: 95vw; height: 100vh;"
    id="myGrid"
    class="ag-theme-alpine"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowData]="rowData"
    (gridReady)="onGridReady($event)"
    rowSelection="multiple"
    [pagination]="true"
    [paginationPageSize]=20
  
  
>
</ag-grid-angular>