import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { BrowserUtils } from "@azure/msal-browser";

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(private msalservice:MsalService) { }

  ngOnInit(): void {
     this.msalservice.logoutRedirect({
    // If in iframe, dont perform redirect to AAD
    onRedirectNavigate: () => {
    return !BrowserUtils.isInIframe();
    }
    });   
  }
  logout(){
    localStorage.clear();
    this.msalservice.logout();
  }
}
