import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms'
import{AppServiceService} from '../../app-service.service';
import { QueryBuilderConfig } from 'angular2-query-builder';
import { RouterModule, Routes,Router } from '@angular/router';

export interface UsersData {
  name: string;
  id: number;
}
interface LocalAuthorities {
  localauthoritydesc: string;
  localauthoritycode: string;
}

@Component({
  selector: 'app-dialog-box',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent {

  action:string="new";
  local_data:any;

  localAuthorities: LocalAuthorities[] = [];

  query = {
    condition: 'and',
    rules: [
      {field: 'JobId', operator: '=', value: 'Bob123'},
      {field: 'JobStatus', operator: '=', value: 'InProcess'}
     
    ]
  };
  
  config: QueryBuilderConfig = {
    fields: {
      JobId: {name: 'JobId', type: 'string'},
      JobAssignedTo: {name: 'JobAssignedTo', type: 'string'},
     
      JobStatus: {
        name: 'JobStatus',
        type: 'category',
        options: [
          {name: 'In Process', value: 'In Process'},
          {name: 'For QC', value: 'For QC'},
          {name: 'For QA', value: 'For QA'}
        ]
      }
    }
  }


  // newJobForm = new FormGroup({
  
  //   jobId: new FormControl(null, [Validators.required]),
  //   jobDesc: new FormControl(null, [Validators.required]),
  //   lacode: new FormControl(null, [Validators.required]),
  // });
  constructor(private router: Router,
    public dialogRef: MatDialogRef<PopupComponent>,private service:AppServiceService,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: UsersData) {
    console.log(data);
    this.local_data = {...data};
    console.log('data here',)
    this.action = this.local_data.action;
  
  }
  ngOnInit(): void {
  //  if (this.action=='Add Job')
  //  {
    //this.getLAFromAPI();

   //}
    }
  
  ngAfterViewInit() {
  switch(this.action){
    case 'Add Job':
      console.log('Add'); 
      break;
      case 'Update':     
       this.local_data.localauthoritydesc=1;    
       
      console.log('Update');break;
      case 'Delete':
       console.log('Delete');

  }
}

// get local authorities
getLAFromAPI(){    
  this.service.getLocalauthorities()
  .subscribe(
   data=>{
     this.localAuthorities=data;
   });
   

}

  doAction(){
    if(this.action=='Close')
    {
      this.router.navigate(['logout']);
    }

    if(this.action=='Querybuilder')
    {
      this.dialogRef.close({event:this.action,data:this.query});
    }
    else
    {
      this.dialogRef.close({event:this.action,data:this.local_data});
    }
    switch(this.action){
      case 'Add Job':
        console.log('value is',this.local_data);
        this.postJob(this.local_data);
         
        console.log('Add'); //Add api call param this.local_data

        break;
        case 'Update': 
        console.log('edit',this.local_data);
        this.updateJob(this.local_data)    
          //Update api call   param this.local_data         
        console.log('Update');break;
        case 'Delete':
          this.DeleteJob(this.local_data)    
         console.log('Delete');//Delete api callparam this.local_data
         break;
         case 'Querybuilder':          
         //this.local_data=this.query;
         console.log('querybuilder',this.local_data);
         console.log('query',this.query);
         console.log('config',this.config);    break;
    }
  }




  closeDialog(){
    this.dialogRef.close({event:'Cancel'});
  }
  // onSubmit() {
  //    console.log('value is',this.newJobForm.value);// POst this to DB
  //   this.postJob(this.newJobForm.value);
  //  }

   postJob(param:any)
{
  this.service.postJob(param)
  .subscribe(
   data=>{
    console.log(data);
     //this.dataSource=data;
   });
}

updateJob(param:any){
  this.service.updateJob(param)
  .subscribe(
   data=>{
    console.log(data);
     //this.dataSource=data;
   });
}
DeleteJob(param:any){

}
}