<div>
    <div class="headertoolbar">  
      <mat-nav-list style="display: flex;flex-direction: row;align-items: center;justify-content: center;">   
        <mat-list-item> 
            <mat-form-field  class="example-full-width">
                <mat-label>Select Report Type</mat-label>
            <mat-select  [(ngModel)]="reporttypeselected" (selectionChange)="selectedValue($event)">
                <mat-option *ngFor="let rpt of reporttype" [value]="rpt.value">
                {{rpt.viewValue}}
                </mat-option>
            </mat-select>
            </mat-form-field>         
        </mat-list-item>
        <mat-list-item> 
            <!-- <mat-form-field class="example-form-field" appearance="fill">
                <mat-label>Select Date</mat-label>
                <mat-date-range-input
                  [formGroup]="campaignOne"
                  [rangePicker]="campaignOnePicker"
                  [comparisonStart]="campaignTwo.value.start"
                  [comparisonEnd]="campaignTwo.value.end">
                  <input matStartDate placeholder="Start date" formControlName="start">
                  <input matEndDate placeholder="End date" formControlName="end">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
              </mat-form-field> -->
              <div  *ngIf="show">
              <form [formGroup]="range">
              <mat-form-field appearance="fill">
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input  [rangePicker]="picker">
                  <input matStartDate formControlName="start"  placeholder="Start date">
                  <input matEndDate formControlName="end"  placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker  [calendarHeaderComponent]="ExampleHeaderComponentComponent" [disabled]="!isChartExist"></mat-date-range-picker>
              
                <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
              </mat-form-field>
            </form></div>
        </mat-list-item>
        <mat-list-item>
            <button mat-raised-button (click)="generateReport()">Generate</button>             
        </mat-list-item> 
        <mat-list-item> 
          <button mat-raised-button id="btnExcel" *ngIf="this.isButtonVisible" (click)="importExcel()">Export to Excel</button>        
        </mat-list-item>          
    </mat-nav-list>
  </div>  
  <div>   
     <mat-tab-group (selectedIndexChange)="_setDataSource($event)"  dynamicHeight=5%>
        <mat-tab >
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">list_alt</mat-icon>
            Report
          </ng-template>   
         
          <div class="grid-wrapper">
          <ag-grid-angular         
            class="ag-theme-alpine"        
            #agGrid1
            style="width:100%;height:100%;"
            id="myGrid"
            class="ag-theme-alpine"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [rowData]="rowData"
             [pagination]="true"          
            (gridReady)="onGridReady($event)"
            [overlayLoadingTemplate]="loadingTemplate"
            [overlayNoRowsTemplate]="noRowsTemplate"
            rowSelection="multiple" [groupIncludeTotalFooter]="true">
          </ag-grid-angular>  </div>    
        </mat-tab>      
        <mat-tab >
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">verified_user</mat-icon>
            Chart
          </ng-template>
          
          <ag-charts-angular class="chart"        
          [options]="options"
        ></ag-charts-angular> 
        </mat-tab>
      
      </mat-tab-group>
  </div>
</div>


  
  
 