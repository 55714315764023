<div class="headertoolbar_newuser">  
    <mat-nav-list style="display: flex;flex-direction: row;align-items: center;justify-content: center;">  
    <!--<mat-list-item> 
        <mat-form-field >
       <mat-label>Select User</mat-label>
        <mat-select [(ngModel)]="userid" (ngModelChange)='onUserSelected($event)'>
          <mat-option [value]="0" >Select All</mat-option>
              <mat-option *ngFor="let user of users"   [value]="user.emailid">
            {{user.emailid}}
          </mat-option>
        </mat-select> 
      </mat-form-field>
       </mat-list-item>-->
          <mat-list-item>
            <button mat-raised-button (click)="Add_UpdateUser();"><span *ngIf="isUserSelected" >Update User</span><span *ngIf="!isUserSelected" >Add User</span></button>                        
          </mat-list-item>      
          <mat-list-item>
            <button mat-raised-button (click)="Delete_User();"> Delete User
              <!-- <span *ngIf="isUserSelected" >Delete User</span> -->
            </button>                        
          </mat-list-item>         
          <mat-list-item> 
            <button mat-raised-button (click)="importExcel()">Export to Excel</button>            
          </mat-list-item>
          <mat-list-item> 
            <button mat-raised-button (click)="Refresh()">Refresh</button>                
          </mat-list-item>
     </mat-nav-list>
</div>
<br/>
<div>
    <!-- <h3 style=float:right;padding-right:200px;>{{latext}}</h3> -->
     <mat-tab-group (selectedIndexChange)="_setDataSource($event)"  dynamicHeight=5%>
     
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">supervised_user_circle</mat-icon>
            Users
          </ng-template>     
          <div class="grid-wrapper">  
              <!-- style="width:100vw;height:80vh;" -->       
          <ag-grid-angular         
            class="ag-theme-alpine"        
            #agGridUser                    
            id="agGridUser"
            class="ag-theme-alpine"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"            
            [rowData]="rowData"
             [pagination]="true"    
             [paginationPageSize]="20"      
            (gridReady)="onGridReady($event)"
            (paginationChanged)="onPaginationChanged($event)"
            [overlayLoadingTemplate]="loadingTemplate"
            [overlayNoRowsTemplate]="noRowsTemplate"
            rowSelection="single"            
            (selectionChanged) ='onSelectionChanged($event)'>
          </ag-grid-angular> 
         </div>    
        </mat-tab>
      
        <!-- <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">verified_user</mat-icon>
            QC Jobs
          </ng-template>
          <div class="grid-wrapper">
          <ag-grid-angular         
          class="ag-theme-alpine"        
          #agGrid2
          style="width:100%; height:100%;"
          id="myGridQC"
          class="ag-theme-alpine"
          [columnDefs]="columnDefsQC"
          [defaultColDef]="defaultColDef"
          [rowData]="rowDataQC"
           [pagination]="true"         
           (gridReady)="onGridReadyQC($event)"
          rowSelection="multiple"          
          >
        </ag-grid-angular>
        </div>
        </mat-tab>
      
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">bookmark</mat-icon>
            QA Jobs
          </ng-template>
          <div class="grid-wrapper">
          <ag-grid-angular         
          class="ag-theme-alpine"        
          #agGrid3
          style="width:100%; height:100%;"
          id="myGridQA"
          class="ag-theme-alpine"
          [columnDefs]="columnDefsQA"
          [defaultColDef]="defaultColDef"
          [rowData]="rowDataQA"
           [pagination]="true"        
           (gridReady)="onGridReadyQA($event)"
          rowSelection="multiple">
        </ag-grid-angular></div>
        </mat-tab> -->
      </mat-tab-group>
  </div>