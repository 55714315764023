import {AfterViewInit, ViewChild, Component, OnInit,ElementRef} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import{AppServiceService} from '../app-service.service';
import{Tqtjobs} from './tqtjobs.model';
import{BreakpointObserver} from "@angular/cdk/layout";
import * as XLSX from 'xlsx';//Import Excel
import {MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PopupComponent } from '../job-schedule/popup/popup.component';
import { TestBed } from '@angular/core/testing';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { HttpClient,HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { QueryBuilderConfig } from 'angular2-query-builder';
import { ColDef } from 'ag-grid-community';
import { AgGroupComponent } from 'ag-grid-community';
import {MatSnackBar} from '@angular/material/snack-bar';

const baseUrl=environment.baseUrl;

export interface JobElement {
  jobid: string;
  jobdesc:string;
  assignedto: string;
  jobstatus: number;
  localauthoritydesc: string;
  status:number; 
}
interface User {
  emailid: string;
  ID:number;
  //viewValue: string;
}
interface LocalAuthorities {
  localauthoritydesc: string;
  localauthoritycode: string;
}
interface Jobtype {
  value: string;
  viewValue: string;
}
 /**Mock Data */
 //const ELEMENT_DATA:jobList[]=[];
 
@Component({
  selector: 'app-tqtjobs',
  templateUrl: './tqtjobs.component.html',
  styleUrls: ['./tqtjobs.component.scss']
})
export class TqtjobsComponent {
  durationInSeconds = 5;
  minRowHeight:any;
  gridApi:any;
  gridColumnApi:any;

  gridApiQC:any;
  gridColumnApiQC:any;

  gridApiQA:any;
  gridColumnApiQA:any;

  columnDefs:any;
  columnDefstest:any;
  columnDefsQC:any;
  columnDefsQA:any;
  defaultColDef:any;
  rowData: [];
  rowDatatest: [];
  rowDataQC: [];
  rowDataQA: [];
  autoGroupColumnDef:any;
  noRowsTemplate:any;
 loadingTemplate:any;

  jobtype: Jobtype[] = [
    {value: 'JD', viewValue: 'Jobs'},
    {value: 'JQC', viewValue: 'QC'},
    {value: 'JQA', viewValue: 'QA'},
  ];
 
  loaderImage:Boolean=false;
  users: User[] = [];
  lavalue:string;
  localAuthorities: LocalAuthorities[] = [];
  selectedla="";
  id:string="0";
  useremail:string="0";
  la:any="";
  tabid:string="";
  masterSelected:boolean;
  eGridDiv:any;

 
  selection = new SelectionModel<Tqtjobs>(true, []);  
  searchValue="";
   dataSource:any;
   dataSourceqc:any;
   dataSourceqa:any;
  userid:string;
  latext:any;

  @ViewChild('agGrid1', { static: true }) agGrid1: AgGroupComponent;
  @ViewChild('agGrid2', { static: true }) agGrid2: AgGroupComponent;
 @ViewChild('paginator') paginator: MatPaginator;
 @ViewChild('paginator2') paginator2: MatPaginator;
 @ViewChild('paginator3') paginator3: MatPaginator;
 

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatSort, {static: false}) sort1: MatSort;
  @ViewChild(MatSort, {static: false}) sort2: MatSort;

  constructor(private _snackBar: MatSnackBar,private service:AppServiceService,private matDialog: MatDialog,private observer: BreakpointObserver,private http : HttpClient,private route: ActivatedRoute,private elementRef: ElementRef) {
  //   this.loadingTemplate =
  // `<span class="ag-overlay-loading-center">data is loading...</span>`;
  // // this.noRowsTemplate =
  // // `<span>Still Loading...</span>`;
  this.latext=localStorage.getItem('latext');

  this.loadingTemplate =
      '<span class="ag-overlay-loading-center">Please wait while your jobs are loading</span>';
    this.noRowsTemplate =
      '<span style="padding: 10px; border: 2px solid #444; background: #0099a8;">No Jobs </span>';

    this.masterSelected = false;   

    this.columnDefs = [

      {
        headerName:this.latext,
          children:[
        {
          
        field: 'select',
        maxWidth: 50,
        checkboxSelection: this.checkboxSelection,
       headerCheckboxSelection: this.headerCheckboxSelection,
      },
      {  headerName: "Job Id",field: 'meta_data_scanned_doc_name', sortable: true },     
      { headerName: "Job Assigned To",field: 'job_assigned_to', sortable: true, filter: true },
      {  headerName: "Local Authority Code", field: 'meta_data_local_authority_code', sortable: true, filter: true },   
      {  headerName: "Batch Code", field: 'Batch_Code', sortable: true, filter: true },
      {  headerName: "Meta Data Scanned Input Sub Type", field: 'meta_data_scanned_input_sub_type', sortable: true, filter: true },
    
    ],}
  ];
  //   this.columnDefsQC = [
  //     {
  //       headerName:this.latext,
  //         children:[
  //       {
          
  //       field: 'select',
  //       maxWidth: 50,
  //       checkboxSelection: this.checkboxSelection,
  //      headerCheckboxSelection: this.headerCheckboxSelection,
  //     },
  //     {  headerName: "Job Id",field: 'jobid', sortable: true },     
  //     {headerName: "QC Assigned To", field: 'assignedto', sortable: true, filter: true },
  //     {  headerName: "Local Authority Desc",field: 'localauthoritydesc', sortable: true, filter: true },   
  //     {  headerName: "Job Status",field: 'jobstatus', sortable: true, filter: true },     
  //     {  headerName: "Charge Address",field: 'ChargeAddress', sortable: true, filter: true },
  //     {  headerName: "Charge Document",field: 'ChargeDocument', sortable: true, filter: true }
  //   ],}
  // ];

  //   this.columnDefsQA = [
  //     {
  //       headerName:this.latext,
  //         children:[
  //       {
          
  //       field: 'select',
  //       maxWidth: 50,
  //       checkboxSelection: this.checkboxSelection,
  //      headerCheckboxSelection: this.headerCheckboxSelection,
  //     },     
  //     {  headerName: "Job Id",field: 'jobid', sortable: true },     
  //     {headerName: "QA Assigned To", field: 'assignedto', sortable: true, filter: true },
  //     {  headerName: "Local Authority Desc",field: 'localauthoritydesc', sortable: true, filter: true },   
  //     {  headerName: "Job Status",field: 'jobstatus', sortable: true, filter: true },     
  //     {  headerName: "Charge Address",field: 'ChargeAddress', sortable: true, filter: true },
  //     {  headerName: "Charge Document",field: 'ChargeDocument', sortable: true, filter: true }
  //   ],}
  // ];

    this.autoGroupColumnDef = {
      headerName: 'Group',
      minWidth: 170,
      field: 'select',
      valueGetter: function (params:any) {
        if (params.node.group) {
          return params.node.key;
        } else {
          return params.data[params.colDef.field];
        }
      },
      headerCheckboxSelection: true,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: { checkbox: true },
    };
    this.defaultColDef = {
     
      sortable: true,     
      filter: true,
      flex: 1,
      minWidth: 100,
    
    };  
   }

  //OnInit
  ngOnInit(): void
  {  
    this.tabid="jobtab";     
    this.la = localStorage.getItem('laid')//params.la;       
    console.log(this.la); // price  
    this.getLAFromAPI();
    this.getUsersFromAPI();
    this.lavalue=this.la;
  }

  //AfterViewInit
 ngAfterViewInit()  {
 // alert('Afterviewoninit');
    if( this.dataSource){
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      }
      // if( this.dataSourceqc){
      //   this.dataSourceqc.paginator = this.paginator2;
      // this.dataSourceqc.sort = this.sort1;
      //   }
      //   if( this.dataSourceqa){
      //     this.dataSourceqa.paginator = this.paginator3;
      //   this.dataSourceqa.sort = this.sort2;
      //     }       
 }

 getSelectedRowData() {
  
  let selectedNodes = this.gridApi.getSelectedNodes();
  //console.log('test',this.gridApi.getSelectedNodes())
  let selectedData = selectedNodes.map((node:any)=> node.data);
  alert(`Selected Nodes:\n${JSON.stringify(selectedData)}`);
  return selectedData;
}

onGridReady(params:any) {  
  this.gridApi = params.api;  
  this.gridColumnApi = params.columnApi;
  params.api.showLoadingOverlay();
  setTimeout(() => {
this.getTQTJobsFromAPI(this.la,this.useremail);
  },500);
}

// onGridReadyQC(params:any) {
//   this.gridApiQC = params.api;  
//   this.gridColumnApiQC = params.columnApi;
//   this.getQCDataFromAPI(this.la,this.useremail);
//   //this.gridApiQC.setDomLayout("autoHeight");
// }

// onGridReadyQA(params:any) {
//   this.gridApiQA = params.api;  
//   this.gridColumnApiQA = params.columnApi;
//   this.getQADataFromAPI(this.la,this.useremail);
//   //this.gridApiQA.setDomLayout("autoHeight"); 
// }

 checkboxSelection = function (params:any) {
  return params.columnApi.getRowGroupColumns().length === 0;
};
 headerCheckboxSelection = function (params:any) {
  return params.columnApi.getRowGroupColumns().length === 0;
};


 QueryBuilder()
 {
  this.openDialog('Querybuilder', {});  
  }
 
 callsandhya()
 {
  this.service.getsandhyaapi()
  .subscribe(
   data=>{
     console.log('sandhya data',data);
   }); 
 }
//Display LocalAuthorities
 getLAFromAPI(){    
  this.service.getLocalauthorities()
  .subscribe(
   data=>{
     this.localAuthorities=data;
   });  

}
getTQTJobsFromAPI(param:any,userid:any){
   
    this.service.getTQTJobs(param,userid)
    .subscribe(      
     data=>{     
       this.rowData=data;   
     });  
 }

//   //Display QC Jobs
//   getQCDataFromAPI(param:any,userid:any){    
//     this.service.getQCData(param,userid)
//     .subscribe(
//      data=>{
//        this.rowDataQC=data;
//      });
//  }

//   //Display QA Jobs
//   getQADataFromAPI(param:any,userid:any){    
//     this.service.getQAData(param,userid)
//     .subscribe(
//      data=>{
//       this.rowDataQA=data;    
//      });
//  }
 _setDataSource(indexNumber:any) {
  setTimeout(() => {
    switch (indexNumber) {
      case 0:
        this.tabid="jobtab";     
        break;
      case 1:
        this.tabid="qctab";       
        break;
      case 2:
        this.tabid="qatab";        
        break;
    }
  });
}

 onOptionsSelected(value:any){ 
  if (this.tabid=="jobtab")
  {
  
    this.getTQTJobsFromAPI(value,this.useremail);
  }
  // else if (this.tabid=="qctab")
  // {
  //   this.getQCDataFromAPI(value,this.useremail);
  // }
  // else if (this.tabid=="qatab")
  // {
  //   this.getQADataFromAPI(value,this.useremail);
  // }
}


onUserSelected(value:any){ 
  
    if (this.tabid=="jobtab")
    {
      if(this.gridApi.getSelectedNodes().length>0)
        {
          console.log(this.selection.selected);
      //alert("You have selected job already to Assign");
        }
        else
        {
          this.gridApi.showLoadingOverlay();
      setTimeout(() => {
        this.getTQTJobsFromAPI(localStorage.getItem('laid'),value);
      },500);
    
        }
      }
    // else if (this.tabid=="qctab")
    // {
    //   if(this.gridApiQC.getSelectedNodes().length>0)
    //     {
    //       console.log(this.selection.selected);
    //   //alert("You have selected job already to Assign");
    //     }
    //     else
    //     {
    //     this.getQCDataFromAPI(localStorage.getItem('laid'),value);
    //     }
    // }
    // else if (this.tabid=="qatab")
    // {
    //   if(this.gridApiQA.getSelectedNodes().length>0)
    //   {
    //     console.log(this.selection.selected);
    // //alert("You have selected job already to Assign");
    //   }
    //   else
    //   {
    //   this.getQADataFromAPI(localStorage.getItem('laid'),value);
    //   }
    // }
  
  
}


 openDialog(action:any,obj:any) {
   var w1:string;
   var h1:string;
   if (action==='Querybuilder')
  {
    w1='900px';
    h1='600px';
  }
  else
  {
    w1='400px';
    h1='200px';
  }
  obj.action = action;  
  const dialogRef = this.matDialog.open(PopupComponent, {
    width: w1,
    height:h1,
    data:obj
  });
 
  dialogRef.afterClosed().subscribe(result => {
    if(result.event == 'Add'){
      this.getTQTJobsFromAPI(this.la,this.useremail);
     // this.addRowData(result.data);
    }else if(result.event == 'Update'){
      this.getTQTJobsFromAPI(this.la,this.useremail);
     // this.updateRowData(result.data);
    }else if(result.event == 'Delete'){
      console.log('delete rows',this.selection.selected);
      this.deleteRowData(this.selection.selected);
      this.getTQTJobsFromAPI(this.la,this.useremail);
    }
    else if (result.event== 'Re assign'){
      console.log('Re assign');
      this.assignjobconfirm(this.selectedNodes())
   //  this.getDataFromAPI(this.id);
    }
    else if(result.event == 'cancel'){
      console.log('Re assign');
      this.getTQTJobsFromAPI(this.la,this.useremail);
    }
    else if(result.event == 'ok'){
      console.log('ok');
    }
    else if(result.event == 'Querybuilder'){
      console.log('Qdatais',result.data);
    this.sqlQuery(result.data);
     // this.getfilterDataFromAPI(result.data);
    }
   
  });
}


sqlQuery(query:any)
{
 var str = '';
 var strValue='';
  //  console.log('query',this.query);
  //  console.log('config',this.config);
   for (var i = 0; i < query.rules.length; i++) {
    strValue=query.rules[i].value;
    str += query.rules[i].field + query.rules[i].operator + "'" + strValue + "'"
    if(i === query.rules.length-1)
    {
      
    }
    else
    {
      str += query.condition;
    } 
   
    console.log('final query',str);
}
this.getfilterDataFromAPI(str);
}

getfilterDataFromAPI(param:any){  
  // alert(param);
  // console.log('input',param)
  this.service.getDatafilter(param)

  .subscribe(
    
   data=>{     
    // console.log('data of jobs',data)
    this.dataSource=new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
   });   

}

  getjobDataFromAPI(param:any){    
    this.service.getjobData(param)
    .subscribe(
     data=>{
     // this.dataSource=data;
      this.dataSource=new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
     });     
  
 }
  

  //delete job
  deleteRowData(param:any){
    //console.log(param);// To be saved to DB
    this.service.deleteJob(param)
    .subscribe(
     data=>{
      console.log(data);
       //this.dataSource=data;
     });
     
  }

 selectedNodes()
 {
  let selectedNodes;
  let selectedData:any;

  if (this.tabid=="jobtab")
    {
       selectedNodes = this.gridApi.getSelectedNodes(); 
    }
    else if (this.tabid=="qctab")
    {
       selectedNodes = this.gridApiQC.getSelectedNodes(); 
    }
    else if (this.tabid=="qatab")
    {
       selectedNodes = this.gridApiQA.getSelectedNodes(); 
    }     
   selectedData = selectedNodes.map((node:any)=> node.data);
   selectedData=JSON.parse(JSON.stringify(selectedData));
  
   return selectedData
 }
  //Assign Job
  assignjob()
  {
   //this.openDialog('Add Job',{});
     let dialog:string="notassigned";
    // let selectedNodes;
    // let selectedData:any;

    // if (this.tabid=="jobtab")
    //   {
    //      selectedNodes = this.gridApi.getSelectedNodes(); 
    //   }
    //   else if (this.tabid=="qctab")
    //   {
    //      selectedNodes = this.gridApiQC.getSelectedNodes(); 
    //   }
    //   else if (this.tabid=="qatab")
    //   {
    //      selectedNodes = this.gridApiQA.getSelectedNodes(); 
    //   }       
    //  selectedData = selectedNodes.map((node:any)=> node.data);
    //  selectedData=JSON.parse(JSON.stringify(selectedData));
   // alert(`Selected Nodes:\n${JSON.stringify(selectedData)}`);
  //alert(selectedData);

    var uid=this.selectedNodes();   
    console.log('uid',uid);   
        if(this.userid!=null && this.userid!="0")
        {
          for (let i in uid)
          {
            if(uid[i].assignedto !== null)
            {
              dialog="assigned";
            }
          }
       
        }   
        else
        {
          this.openDialog('ok', {});  
        }
      //  if ((dialog)) ?  this.openDialog('Re assign', {}); : console.log('x is less than or equal to y.')

        if (dialog==="assigned")
        {
          this.openDialog('Re assign', {});   
        }    
        else if (dialog==="notassigned" && this.userid !=null)
        {
          this.assignjobconfirm(uid)
        }
    }

    checkUncheckAll() {
      for (var i = 0; i < this.dataSource.length; i++) {
        this.dataSource[i].isSelected = this.masterSelected;
      }
     
    }

    submitQuery(queryinfo:any):void{

      

        this.http.post(`${baseUrl}/assignJob`,queryinfo).subscribe(res => {

         

          console.log('inside postmehtod of sub.function' );//only objects

        

          //alert('Query raised Successfully!');          

         

      },

      (error:any)=> {alert("Unable to raise query!");

     

      });    

   

      }
    assignjobconfirm(param:any)
    {
      
      console.log(param.forEach((x:any)=>x.job_assigned_to=this.userid));     
      console.log('userid',this.userid); 
      if (this.tabid=="jobtab")
      {      
        this.service.assignTQTJob(param)
        .subscribe(
        data=>{
          console.log(data);
          //this.gridApi.Refresh();         
          //this.selection.clear();
          this.getTQTJobsFromAPI(this.la,this.useremail);
          //alert('hi');
         // this.openDialog('okay', {});  
         this._snackBar.open("Jobs Assigned Successfully", "X" ,{
          duration: 3000
          //, panelClass: ['snack-bar-color']

        }); 
        });
       
    
      }
      
      // else if (this.tabid=="qctab")
      // {

      //   this.service.assignQCJob(param)
      //   .subscribe(
      //   data=>{
      //     console.log(data); 
      //    // this.gridApiQC.Refresh(); 
      //    // alert('Jobs Assigned Succssfully');
      //    // this.selection.clear();
      //     this.getQCDataFromAPI(this.la,this.useremail);   
      //     this._snackBar.open("Jobs Assigned Successfully", "X" ,{
      //       duration: 3000
      //       //, panelClass: ['snack-bar-color']
  
      //     });     
      //   });        
      // }

      // else if (this.tabid=="qatab")
      // {
      //   this.service.assignQAJob(param)
      //   .subscribe(
      //   data=>{
      //     console.log(data);  
      //    // alert('Jobs Assigned Succssfully');
      //   //  this.selection.clear();
      //     this.getQADataFromAPI(this.la,this.useremail);     
      //     this._snackBar.open("Jobs Assigned Successfully", "X" ,{
      //       duration: 3000, panelClass: ['snack-bar-color']
  
      //     }); 
      //   });        
      // }
    
    

    }

//Refresh

Refresh()
{
  this.selection.clear();
  this.lavalue='';
  this.userid='';
  
    this.getTQTJobsFromAPI(this.la,this.useremail);
  
    // this.getQCDataFromAPI(this.la,this.useremail);
 
    // this.getQADataFromAPI(this.la,this.useremail);
  

}

//importExcel


importExcel()
{
  if (this.tabid=="jobtab")
  {
   var uid=this.selectedNodes();
      if(uid.length != 0)
      {
        this.importexceldata(uid);          
      }
      else
      {
          this.service.getData(this.la,this.useremail)
          .subscribe(
          data=>{
            this.importexceldata(data);
          });
      }      
  }
  else if (this.tabid=="qctab")
  {
      var uid=this.selectedNodes();
      if(uid !== null)
      {
        this.importexceldata(uid);          
      }
      else
      {
          this.service.getQCData(this.la,this.useremail)
          .subscribe(
          data=>{
            this.importexceldata(data);
          });
      } 
  }
  else if (this.tabid=="qatab")
  {
      var uid=this.selectedNodes();
        if(uid !== null)
        {
          this.importexceldata(uid);          
        }
        else
        {
            this.service.getQAData(this.la,this.useremail)
            .subscribe(
            data=>{
              this.importexceldata(data);
            });
        }
  } 
}
  
//import excel function inside
importexceldata(data:any)
{
  const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(data)
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'JobList'); 
  const now = new Date();
console.log('now',now);
  XLSX.writeFile(wb, 'JobList'+now+'.xlsx');
}


  //load users list

  getUsersFromAPI(){    
    this.service.getClampUsers('TQT',''+this.la)
    .subscribe(
     data=>{
       this.users=data;
     });
     
  
 }

//real one 
 isAllSelected() {
  const numSelected = this.selection.selected.length;
  const numRows = this.dataSource.data.length;
  return numSelected === numRows;
}



 masterToggle() {
  this.isAllSelected() ?
      this.selection.clear():
      this.dataSource.data.forEach((row:any) => this.selection.select(row));
}



checkboxLabel(row?: Tqtjobs): string {
  if (!row) {
    return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
  }
  return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.jobid + 1}`;
}

checkboxLabel1(row?: Tqtjobs): string {
  if (!row) {
    return `${this.isAllSelected1() ? 'select' : 'deselect'} all`;
  }
  return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.jobid + 1}`;
}
isAllSelected1() {
  const numSelected = this.selection.selected.length;
  const numRows = this.dataSourceqc.data.length;
  return numSelected === numRows;
}

masterToggle1() {
  this.isAllSelected1() ?
      this.selection.clear() :
      this.dataSourceqc.data.forEach((row:any) => this.selection.select(row));
}

checkboxLabel2(row?: Tqtjobs): string {
  if (!row) {
    return `${this.isAllSelected2() ? 'select' : 'deselect'} all`;
  }
  return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.jobid + 1}`;
}
isAllSelected2() {
  const numSelected = this.selection.selected.length;
  const numRows = this.dataSourceqa.data.length;
  return numSelected === numRows;
}

masterToggle2() {
  this.isAllSelected2() ?
      this.selection.clear() :
      this.dataSourceqa.data.forEach((row:any) => this.selection.select(row));
}


//  /** TODO Implement Row selection */
   /** Whether the number of selected elements matches the total number of rows. */
  //  isAllSelected() {
  //   const numSelected = this.selection.selected.length;
  //   const numRows = this.dataSource.length;
  //   return numSelected === numRows;
  // }

  // // /** Selects all rows if they are not all selected; otherwise clear selection. */
  // masterToggle() {
  //   if (this.isAllSelected()) {
  //     this.selection.clear();
  //     return;
  //   }

  //   this.selection.select(...this.dataSource);
  // }

  // /** The label for the checkbox on the passed row */
  // checkboxLabel(row?: jobList): string {
  //   if (!row) {
  //     return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
  //   }
  //   return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.jobid + 1}`;
  // }

  //filter
 
  applyFilter(filterValue: string) {
    console.log('filter',filterValue);
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches   

    if (this.tabid=="jobtab")
    {
     // this.rowData.filter= filterValue;
     // this.dataSource.filter = filterValue;
    }
    else if (this.tabid=="qctab")
    {
      this.dataSourceqc.filter = filterValue;
    }
    else if (this.tabid=="qatab")
    {
      this.dataSourceqa.filter = filterValue;
    }

   
  }
  
  //To JSON
  onSelectExcel(evt:any) {
    console.log('hit excel func');
    let workBook:any = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = evt.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial:any, name:any) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
      

        return initial;
      }, {});
      const dataString = JSON.stringify(jsonData.Sheet1);
      console.log('data',dataString);// To be saved to DB
     this.saveExcelToDB(dataString);
     this.openDialog('okay', {});  
   // this.getDataFromAPI(this.id);

    }
    reader.readAsBinaryString(file);
  }

  //POst Data from Excel to db
  saveExcelToDB(param:any){
    console.log('param',param);// To be saved to DB
    if (this.tabid=="jobtab")
  {
    this.service.UploadFromExcelToDb(param)
    .subscribe(
     data=>{
      console.log(data);
      this.getTQTJobsFromAPI(this.la,this.useremail);
       //this.dataSource=data;
     });
     
  }
 
  else if (this.tabid=="qctab")
  {
    this.service.postnewJobQCBulk(param)
    .subscribe(
     data=>{
      console.log(data);
       //this.dataSource=data;
     });
     
  }
  else if (this.tabid=="qatab")
  {
    this.service.postnewJobQABulk(param)
    .subscribe(
     data=>{
      console.log(data);
       //this.dataSource=data;
     });
     
  }
}
   
}

