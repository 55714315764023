import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component(
{
  selector: 'app-testjob',
  template: `Dashboard`,
  styleUrls: ['./testjob.component.scss']
})
export class TestjobComponent  {

  //  gridApi:any;
  //  gridColumnApi:any;

  //  columnDefs:any;
  //  defaultColDef:any;
  //  rowSelection:any;
  //  rowData:[];

  constructor(private http: HttpClient) {
    // this.columnDefs = [
    //   {
    //     field: 'athlete',
    //     minWidth: 150,
    //   },
    //   {
    //     field: 'age',
    //     maxWidth: 90,
    //   },
    //   {
    //     field: 'country',
    //     minWidth: 150,
    //   },
    //   {
    //     field: 'year',
    //     maxWidth: 90,
    //   },
    //   {
    //     field: 'date',
    //     minWidth: 150,
    //   },
    //   {
    //     field: 'sport',
    //     minWidth: 150,
    //   },
    //   { field: 'gold' },
    //   { field: 'silver' },
    //   { field: 'bronze' },
    //   { field: 'total' },
    // ];
    // this.defaultColDef = {
    //   flex: 1,
    //   minWidth: 100,
    // };
    // this.rowSelection = 'single';
  }

  // onSelectionChanged() {
  //   const selectedRows = this.gridApi.getSelectedRows();
  //   // document.querySelector('#selectedRows').innerHTML =
  //   //   selectedRows.length === 1 ? selectedRows[0].athlete : '';
  // }

  // onGridReady(params:any) {
  //   this.gridApi = params.api;
  //   this.gridColumnApi = params.columnApi;

  //   this.http
  //     .get('https://www.ag-grid.com/example-assets/olympic-winners.json')
  //     .subscribe((data) => 
  //    params.api.setRowData(data)
    
  //     );

  //     // this.gridApi.setDomLayout("autoHeight");
      
  // }

}
