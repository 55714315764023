import {AfterViewInit, ViewChild, Component, OnInit,ElementRef} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import{AppServiceService} from '../app-service.service';
import{jobList} from './JobScheduleComponent.Model';
import * as XLSX from 'xlsx';//Import Excel

import {MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PopupComponent } from './popup/popup.component';
import { TestBed } from '@angular/core/testing';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { HttpClient,HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { QueryBuilderConfig } from 'angular2-query-builder';
import { ColDef } from 'ag-grid-community';
import { AgGroupComponent } from 'ag-grid-community';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Alert } from 'selenium-webdriver';
import { Observable } from 'rxjs';


const baseUrl=environment.baseUrl;

export interface JobElement {
  jobid: string;
  jobdesc:string;
  assignedto: string;
  jobstatus: number;
  localauthoritydesc: string;
  status:number; 
}
interface User {
  emailid: string;
  ID:number
  //viewValue: string;
}
interface LocalAuthorities {
  localauthoritydesc: string;
  localauthoritycode: string;
}
interface Jobtype {
  value: string;
  viewValue: string;
}
 /**Mock Data */
 const ELEMENT_DATA:jobList[]=[];
 
@Component({
  templateUrl: './job-schedule.component.html',
  styleUrls: ['./job-schedule.component.scss']
})
export class JobScheduleComponent {
  schema:any;
  durationInSeconds = 5;
  minRowHeight:any;
  gridApi:any;
  gridColumnApi:any;

  gridApiQC:any;
  gridColumnApiQC:any;

  gridApiQA:any;
  gridColumnApiQA:any;

  columnDefs:any;
  columnDefstest:any;
  columnDefsQC:any;
  columnDefsQA:any;
  defaultColDef:any;
  rowData: [];
  //public rowData: Observable<[]>;
  rowDataTemp: [];
  rowDataeach: [];
  rowDatatest: [];
  rowDataQC: [];
  rowDataQA: [];
  autoGroupColumnDef:any;
  noRowsTemplate:any;
 loadingTemplate:any;
 accountNumber:any;
  jobtype: Jobtype[] = [
    {value: 'JD', viewValue: 'Jobs'},
    {value: 'JQC', viewValue: 'QC'},
    {value: 'JQA', viewValue: 'QA'},
  ];
 
  loaderImage:Boolean=false;
  users: User[] = [];
  lavalue:string;
  localAuthorities: LocalAuthorities[] = [];
  selectedla="";
  id:string="0";
  useremail:string="0";
  la:any="";
  tabid:string="";
  masterSelected:boolean;
  eGridDiv:any;

 
  selection = new SelectionModel<jobList>(true, []);  
  searchValue="";
   dataSource:any;
   dataSourceqc:any;
   dataSourceqa:any;
  userid:string;
  latext:any;

  @ViewChild('agGrid1', { static: true }) agGrid1: AgGroupComponent;
  @ViewChild('agGrid2', { static: true }) agGrid2: AgGroupComponent;
 @ViewChild('paginator') paginator: MatPaginator;
 @ViewChild('paginator2') paginator2: MatPaginator;
 @ViewChild('paginator3') paginator3: MatPaginator;
 

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatSort, {static: false}) sort1: MatSort;
  @ViewChild(MatSort, {static: false}) sort2: MatSort;

  constructor(private _snackBar: MatSnackBar,private service:AppServiceService,private matDialog: MatDialog,private http : HttpClient,private route: ActivatedRoute,private elementRef: ElementRef) {
 
  this.latext=localStorage.getItem('latext');
  this.loadingTemplate =
      '<span class="ag-overlay-loading-center">Please wait while your jobs are loading</span>';
    this.noRowsTemplate =
      '<span style="padding: 10px; border: 2px solid #444; background: #0099a8;">No Jobs </span>';

    this.masterSelected = false;   

    this.columnDefs = [

      {
        headerName:this.latext,
          children:[
        {
          
        field: 'select',
        maxWidth: 50,
        checkboxSelection: true,
       headerCheckboxSelection: true,
      },
      {  headerName: "Job Id",field: 'jobid', sortable: true }, 
      {  headerName: "Charge Document",field: 'ChargeDocument', sortable: true, filter: true },  
      {  headerName: "Charge Address",field: 'ChargeAddress', sortable: true, filter: true,wrap:true},  
      { headerName: "Assigned To",field: 'assignedto', sortable: true, filter: true },
      // {  headerName: "Local Authority Desc", field: 'localauthoritydesc', sortable: true, filter: true },   
      {  headerName: "Job Status",field: 'jobstatus', sortable: true, filter: true },
      {  headerName: "old_register_part",field: 'old_register_part', sortable: true, filter: true },   
      {  headerName: "charge_sub_category",field: 'charge_sub_category', sortable: true, filter: true }
     
    ],}
  ];
    this.columnDefsQC = [
      {
        headerName:this.latext,
          children:[
        {
          
        field: 'select',
        maxWidth: 50,
        checkboxSelection: this.checkboxSelection,
       headerCheckboxSelection: this.headerCheckboxSelection,
      },
      {  headerName: "Job Id",field: 'jobid', sortable: true },
      {  headerName: "Charge Document",field: 'ChargeDocument', sortable: true, filter: true },
      {  headerName: "Charge Address",field: 'ChargeAddress', sortable: true, filter: true },    
      {headerName: "QC Assigned To", field: 'assignedto', sortable: true, filter: true },
      // {  headerName: "Local Authority Desc",field: 'localauthoritydesc', sortable: true, filter: true },   
      {  headerName: "Job Status",field: 'jobstatus', sortable: true, filter: true },    
      {  headerName: "old_register_part",field: 'old_register_part', sortable: true, filter: true },   
      {  headerName: "charge_sub_category",field: 'charge_sub_category', sortable: true, filter: true },
      
    ],}
  ];

    this.columnDefsQA = [
      {
        headerName:this.latext,
          children:[
        {
          
        field: 'select',
        maxWidth: 50,
        checkboxSelection: this.checkboxSelection,
       headerCheckboxSelection: this.headerCheckboxSelection,
      },     
      {  headerName: "Job Id",field: 'jobid', sortable: true },
      {  headerName: "Charge Document",field: 'ChargeDocument', sortable: true, filter: true }, 
      {  headerName: "Charge Address",field: 'ChargeAddress', sortable: true, filter: true },    
      {headerName: "QA Assigned To", field: 'assignedto', sortable: true, filter: true },
      // {  headerName: "Local Authority Desc",field: 'localauthoritydesc', sortable: true, filter: true },   
      {  headerName: "Job Status",field: 'jobstatus', sortable: true, filter: true },     
      {  headerName: "old_register_part",field: 'old_register_part', sortable: true, filter: true },   
      {  headerName: "charge_sub_category",field: 'charge_sub_category', sortable: true, filter: true },
     
    ],}
  ];

    this.autoGroupColumnDef = {
      headerName: 'Group',
      minWidth: 170,
      field: 'select',
      valueGetter: function (params:any) {
        if (params.node.group) {
          return params.node.key;
        } else {
          return params.data[params.colDef.field];
        }
      },
      headerCheckboxSelection: true,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: { checkbox: true },
    };
    this.defaultColDef = {
     
      sortable: true,     
      filter: true,
      flex: 1,
      minWidth: 100
     
    
    };  
   }

  //OnInit
  ngOnInit(): void
  {    
    this.tabid="jobtab";     
    this.la = localStorage.getItem('laid')//params.la;       
    console.log(this.la); // price 
    this.getUsersFromAPI();   
    this.lavalue=this.la;
  }

  //AfterViewInit
 ngAfterViewInit()  {
      //console.log(this.rowData);
      console.log("ngafterviewtime"+(new Date().toLocaleString()));
 }

 getSelectedRowData() {  
  let selectedNodes = this.gridApi.getSelectedNodes(); 
  let selectedData = selectedNodes.map((node:any)=> node.data); 
  return selectedData;
}

//load users list

getUsersFromAPI(){    
  this.service.getClampUsers('WAT',''+this.la)
  .subscribe(
   data=>{
     this.users=data;
   });
}
onGridReady(params:any) {  
  this.gridApi = params.api;  
  this.gridColumnApi = params.columnApi;
  params.api.showLoadingOverlay();
  
  console.log("onGridReady : "+(new Date().toLocaleString()));
  setTimeout(() => {
    
this.getDataFromAPI(this.la,this.useremail);
  },200);
}

onGridReadyQC(params:any) {
  this.gridApiQC = params.api;  
  this.gridColumnApiQC = params.columnApi;
  params.api.showLoadingOverlay();
  setTimeout(() => {
  this.getQCDataFromAPI(this.la,this.useremail);  
},200);
}

onGridReadyQA(params:any) {
  this.gridApiQA = params.api;  
  this.gridColumnApiQA = params.columnApi;
  params.api.showLoadingOverlay();
  setTimeout(() => {
  this.getQADataFromAPI(this.la,this.useremail);  
},200);
}
onPaginationChanged(params:any)
{
 //alert('hi');

 //this.gridApi.refreshHeader();
}

 checkboxSelection = function (params:any) {
   
  return params.columnApi.getRowGroupColumns().length === 0;
};
 headerCheckboxSelection = function (params:any) {
 
  return params.columnApi.getRowGroupColumns().length === 0;
};


 QueryBuilder()
 {
  this.openDialog('Querybuilder', {});  
  }


//Display all Jobs

  getDataFromAPI(param:any,userid:any){ 
  
   console.log('before api call', new Date);
    this.service.getData(localStorage.getItem('schema'),userid)
    .subscribe(   
         
     data=>{    
      console.log('data iss', data); 
       this.rowData=data; 
       console.log('after render in grid', new Date);  
     });  
 }

  //Display QC Jobs
  getQCDataFromAPI(param:any,userid:any){ 
      
    this.service.getQCData(localStorage.getItem('schema'),userid)
    .subscribe(
     data=>{
      console.log('data iss QC', data);
       this.rowDataQC=data;
     });
 }

  //Display QA Jobs
  getQADataFromAPI(param:any,userid:any){    
    this.service.getQAData(localStorage.getItem('schema'),userid)
    .subscribe(
     data=>{
      this.rowDataQA=data;    
     });
 }
 _setDataSource(indexNumber:any) {
  setTimeout(() => {
    switch (indexNumber) {
      case 0:
        this.tabid="jobtab";     
        break;
      case 1:
        this.tabid="qctab";       
        break;
      case 2:
        this.tabid="qatab";        
        break;
    }
  });
}

 onOptionsSelected(value:any){ 
  if (this.tabid=="jobtab")
  {
  
    this.getDataFromAPI(value,this.useremail);
  }
  else if (this.tabid=="qctab")
  {
    this.getQCDataFromAPI(value,this.useremail);
  }
  else if (this.tabid=="qatab")
  {
    this.getQADataFromAPI(value,this.useremail);
  }
}


onUserSelected(value:any){ 
  
    if (this.tabid=="jobtab")
    {
      if(this.gridApi.getSelectedNodes().length>0)
        {
          console.log(this.selection.selected);
      //alert("You have selected job already to Assign");
        }
        else
        {
          this.gridApi.showLoadingOverlay();
      setTimeout(() => {
        this.getDataFromAPI(localStorage.getItem('laid'),value);
      },500);
    
        }
      }
    else if (this.tabid=="qctab")
    {
      if(this.gridApiQC.getSelectedNodes().length>0)
        {
          console.log(this.selection.selected);
      //alert("You have selected job already to Assign");
        }
        else
        {
        this.getQCDataFromAPI(localStorage.getItem('laid'),value);
        }
    }
    else if (this.tabid=="qatab")
    {
      if(this.gridApiQA.getSelectedNodes().length>0)
      {
        console.log(this.selection.selected);
    //alert("You have selected job already to Assign");
      }
      else
      {
      this.getQADataFromAPI(localStorage.getItem('laid'),value);
      }
    }
  
  
}


 openDialog(action:any,obj:any) {
   var w1:string;
   var h1:string;
   if (action==='Querybuilder')
  {
    w1='900px';
    h1='600px';
  }
  else
  {
    w1='400px';
    h1='200px';
  }
  obj.action = action;  
  const dialogRef = this.matDialog.open(PopupComponent, {
    width: w1,
    height:h1,
    data:obj
  });
 
  dialogRef.afterClosed().subscribe(result => {
    if(result.event == 'Add'){
      this.getDataFromAPI(this.la,this.useremail);
     // this.addRowData(result.data);
    }else if(result.event == 'Update'){
      this.getDataFromAPI(this.la,this.useremail);
     // this.updateRowData(result.data);
    }else if(result.event == 'Delete'){
      console.log('delete rows',this.selection.selected);
     // this.deleteRowData(this.selection.selected);
      this.getDataFromAPI(this.la,this.useremail);
    }
    else if (result.event== 'Re assign'){
      console.log('Re assign');
      this.assignjobconfirm(this.selectedNodes())
   //  this.getDataFromAPI(this.id);
    }
    else if(result.event == 'cancel'){
      console.log('Re assign');
      this.getDataFromAPI(this.la,this.useremail);
    }
    else if(result.event == 'ok'){
      console.log('ok');
    }
    else if(result.event == 'Querybuilder'){
      console.log('Qdatais',result.data);
    //this.sqlQuery(result.data);
     // this.getfilterDataFromAPI(result.data);
    }
   
  });
}


 selectedNodes()
 {
  let selectedNodes;
  let selectedData:any;

  if (this.tabid=="jobtab")
    {
       selectedNodes = this.gridApi.getSelectedNodes(); 
    }
    else if (this.tabid=="qctab")
    {
       selectedNodes = this.gridApiQC.getSelectedNodes(); 
    }
    else if (this.tabid=="qatab")
    {
       selectedNodes = this.gridApiQA.getSelectedNodes(); 
    }     
   selectedData = selectedNodes.map((node:any)=> node.data);
   selectedData=JSON.parse(JSON.stringify(selectedData));
  
   return selectedData
 }
  //Assign Job
  assignjob()
  {   
     let dialog:string="notassigned";   
    var uid=this.selectedNodes();   
    console.log('uid',uid);   
        if(this.userid!=null && this.userid!="0")
        {
          for (let i in uid)
          {
            if(uid[i].assignedto !== null)
            {
              dialog="assigned";
            }
          }
       
        }   
        else
        {
          this.openDialog('ok', {});  
        }
      //  if ((dialog)) ?  this.openDialog('Re assign', {}); : console.log('x is less than or equal to y.')

        if (dialog==="assigned")
        {
          this.openDialog('Re assign', {});   
        }    
        else if (dialog==="notassigned" && this.userid !=null)
        {
          this.assignjobconfirm(uid)
        }
    }
    
    assignjobconfirm(param:any)
    {
      
      console.log(param.forEach((x:any)=>x.assignedto=this.userid));     
      console.log('userid',this.userid); 
      if (this.tabid=="jobtab")
      {     console.log("job details",param);
        this.service.assignJob(param,localStorage.getItem('schema'))
        .subscribe(
        data=>{
          console.log(data);
          //this.gridApi.Refresh();         
          //this.selection.clear();
          this.getDataFromAPI(this.la,this.useremail);
          //alert('hi');
         // this.openDialog('okay', {});  
         this._snackBar.open("Jobs Assigned Successfully", "X" ,{
          duration: 3000
          //, panelClass: ['snack-bar-color']

        }); 
        });
       
    
      }
      
      else if (this.tabid=="qctab")
      {
        console.log("QC job details",param);
        this.service.assignQCJob(param,localStorage.getItem('schema'))
        .subscribe(
        data=>{
          console.log(data); 
         // this.gridApiQC.Refresh(); 
         // alert('Jobs Assigned Succssfully');
         // this.selection.clear();
          this.getQCDataFromAPI(this.la,this.useremail);   
          this._snackBar.open("Jobs Assigned Successfully", "X" ,{
            duration: 3000
            //, panelClass: ['snack-bar-color']
  
          });     
        });        
      }

      else if (this.tabid=="qatab")
      {
        this.service.assignQAJob(param,localStorage.getItem('schema'))
        .subscribe(
        data=>{
          console.log(data);  
         // alert('Jobs Assigned Succssfully');
        //  this.selection.clear();
          this.getQADataFromAPI(this.la,this.useremail);     
          this._snackBar.open("Jobs Assigned Successfully", "X" ,{
            duration: 3000, panelClass: ['snack-bar-color']
  
          }); 
        });        
      }
    
    

    }

//Refresh

Refresh()
{
  this.selection.clear();
  this.lavalue='';
  this.userid='';
  
    this.getDataFromAPI(this.la,this.useremail);
  
    this.getQCDataFromAPI(this.la,this.useremail);
 
    this.getQADataFromAPI(this.la,this.useremail);

}

//importExcel Function calling here


importExcel()
{
  if (this.tabid=="jobtab")
  {
   var uid=this.selectedNodes();
      if(uid.length != 0)
      {
        this.importexceldata(uid);          
      }
      else
      {
          this.service.getData(localStorage.getItem('schema'),this.useremail)
          .subscribe(
          data=>{
            this.importexceldata(data);
          });
      }      
  }
  else if (this.tabid=="qctab")
  {
      var uid=this.selectedNodes();
      if(uid.length != 0)
      {
        this.importexceldata(uid);          
      }
      else
      {
          this.service.getQCData(localStorage.getItem('schema'),this.useremail)
          .subscribe(
          data=>{
            this.importexceldata(data);
          });
      } 
  }
  else if (this.tabid=="qatab")
  {
      var uid=this.selectedNodes();
        if(uid.length != 0)
        {
          this.importexceldata(uid);          
        }
        else
        {
            this.service.getQAData(localStorage.getItem('schema'),this.useremail)
            .subscribe(
            data=>{
              this.importexceldata(data);
            });
        }
  } 
}
  
//import excel function inside
importexceldata(data:any)
{
  const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(data)
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'JobList'); 
  const now = new Date();
console.log('now',now);
  XLSX.writeFile(wb, 'JobList'+now+'.xlsx');
}

  //To JSON
  onSelectExcel(evt:any) {
    console.log('hit excel func');
    let workBook:any = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = evt.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: 'binary' });
      jsonData = workBook.SheetNames.reduce((initial:any, name:any) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
      

        return initial;
      }, {});
      const dataString = JSON.stringify(jsonData.Sheet1);
      console.log('data',dataString);// To be saved to DB
     this.saveExcelToDB(dataString);
     this.openDialog('okay', {});  
   // this.getDataFromAPI(this.id);

    }
    reader.readAsBinaryString(file);
    //evt.target.value='';
  }


  
  //POst Data from Excel to db
  saveExcelToDB(param:any){
    console.log('paramassign',param);// To be saved to DB
    if (this.tabid=="jobtab")
  {
    this.service.assignJob( JSON.parse(param),localStorage.getItem('schema'))
   // this.service.postnewJob(param)
    .subscribe(
     data=>{
      console.log(data);
       //this.dataSource=data;
     });
     
  }
 
  else if (this.tabid=="qctab")
  {
    this.service.assignQCJob(JSON.parse(param),localStorage.getItem('schema'))
    //this.service.postnewJobQCBulk(JSON.parse(param),localStorage.getItem('schema'))
    .subscribe(
     data=>{
      console.log(data);
       //this.dataSource=data;
     });
     
  }
  else if (this.tabid=="qatab")
  {
    this.service.assignQAJob(JSON.parse(param),localStorage.getItem('schema'))
    //this.service.postnewJobQABulk(param)
    .subscribe(
     data=>{
      console.log(data);
       //this.dataSource=data;
     });
     
  }
}
   
}
function mergeByProperty(target:any, source:any, prop:any) 
{
  source.forEach((sourceElement:any) => {
    target.push(sourceElement);
    //let targetElement = target.find((targetElement:any) => {
      //return sourceElement[prop] === targetElement[prop];
    //})
    //targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
    //target.push(sourceElement);
  })
  console.log(target.length);
  return target;
}
