import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { AgGroupComponent } from 'ag-grid-community';
import { AppServiceService } from '../app-service.service';
import * as XLSX from 'xlsx';//Import Excel

@Component({
  selector: 'app-qrtlareport',
  templateUrl: './qrtlareport.component.html',
  styleUrls: ['./qrtlareport.component.scss']
})
export class QrtlareportComponent implements OnInit {
  
  columnDefs_Sum:any;
  defaultColDef_Sum:any;
  rowDataSummary: []; 

  columnDefs_Out:any;
  defaultColDef_Out:any;
  rowDataOutstanding: []; 

  gridApi:any;
  gridColumnApi:any;

  autoGroupColumnDef:any;
  noRowsTemplate:any;
  loadingTemplate:any;
  dataSource:any;
  @ViewChild('agGridSummary', { static: true }) agGridSummary: AgGroupComponent;  
  @ViewChild('agGridOutstanding', { static: true }) agGridOutstanding: AgGroupComponent;

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  tabid: string;

  laObj: any;
  constructor(private service:AppServiceService) {
    this.columnDefs_Sum = [
      // {
      //   headerName:"S.No",
      //   //   children:[
      //   // {          
      //   field: 'select',
      //   maxWidth: 50,
      //   checkboxSelection: true,
      //  headerCheckboxSelection: true,
      // },
      {  headerName: "Details",field: 'Details', sortable: true },     
      { headerName: "No.of Charges",field: 'Count', sortable: true, filter: true }
    ];
    
    this.defaultColDef_Sum = {     
      sortable: true,     
      filter: true,
      flex: 1,
      minWidth: 50,
      resizable: true
      //,editable:true    
    };
    this.columnDefs_Out=[
      {  headerName: "Cyient Ref ID",field: 'CyientRefID', sortable: true },     
      { headerName: "No.of Days",field: 'OutstatingDays', sortable: true, filter: true }
    ]
    this.defaultColDef_Out = {     
      sortable: true,     
      filter: true,
      flex: 1,
      minWidth: 50,
      resizable: true
      //,editable:true    
    };
   }

  ngOnInit(): void {
  }
  onGridReadySummary(params:any) {
  
    this.gridApi = params.api;  
    this.gridColumnApi = params.columnApi;
    params.api.showLoadingOverlay();
    setTimeout(() => {
  this.getDataFromAPI('Sum');
    },500);
  }
  onGridReadyOutstanding(params:any) {
  
    this.gridApi = params.api;  
    this.gridColumnApi = params.columnApi;
    params.api.showLoadingOverlay();
    setTimeout(() => {
  this.getDataFromAPI('Out');
    },500);
  }
  //Display all Jobs
  getDataFromAPI(report:string){  
    // alert(param);
    // console.log('input',param)
    this.laObj= {
      "la_code":''+localStorage.getItem('laid'),
      "la_desc":''+localStorage.getItem('latext')
    }
    this.service.getQRTLAReports(this.laObj)
    .subscribe(       
     data=>{     
      console.log('data of QRT LA Reports',data);     
      this.rowDataSummary=data[0]; 
      this.rowDataOutstanding=data[1];
      //ladetails.la_code      
     });    
  
 }

  _setDataSource(indexNumber:any) {
    setTimeout(() => {
      switch (indexNumber) {
        case 0:
          this.tabid="Sum";     
          break;
        case 1:
          this.tabid="Out";       
          break;
        
      }
    });
  }
  Refresh()
  {  
     
    this.getDataFromAPI(this.tabid);
  }
  importExcel(){
    this.service.getAllClampUsersList()
     .subscribe(       
      data=>{     
       console.log('user details',data);
       //this.rowData=data[0]; 
       if(this.tabid=='Out')
       {
        this.importexceldata(data[1], 'LA_Awaiting');
       }
       if(this.tabid=='Sum')
       {
        this.importexceldata(data[0],'LA_Summary');         
       }      
      
      });  
  }
  
  //import excel function inside
  importexceldata(data:any,fname:string)
  {
    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(data)
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fname+' List'); 
    const now = new Date();
    console.log('now',now);
    XLSX.writeFile(wb, 'Clamp_'+fname+"_"+now+'.xlsx');
  }
}
