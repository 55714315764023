import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
//import * as internal from 'stream';
import { AppComponent } from '../app.component';
import * as XLSX from 'xlsx';//Import Excel
import{AppServiceService} from '../app-service.service';
import {MatTableDataSource} from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { AgGroupComponent } from 'ag-grid-community';
import { NewUsermodalComponent } from './new-usermodal/new-usermodal.component';
import {MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

const baseUrl=environment.baseUrl;
const graphAPIUrl=environment.graphAPIUrl;
const webURL=environment.WebUrl;
const ADGroup_OID=environment.ADGroup_OID;

@Component({
  selector: 'app-assign-roles-admin',
  templateUrl: './assign-roles-admin.component.html',
  styleUrls: ['./assign-roles-admin.component.scss']
})
export class AssignRolesAdminComponent implements OnInit {

  tabid:string="";

  columnDefs:any;
  defaultColDef:any;
  rowData: [];
  roleslist:[{roleid:number,role:string}];
  selectedObj:any;
  obj:any;
  isUserSelected:boolean=false;

  gridApi:any;
  gridColumnApi:any;

  autoGroupColumnDef:any;
  noRowsTemplate:any;
  loadingTemplate:any;
  dataSource:any;
  @ViewChild('agGridUser', { static: true }) agGridUser: AgGroupComponent;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(private service:AppServiceService,private matDialog: MatDialog,private router: Router,private _snackBar: MatSnackBar) {
    this.getDataFromAPI();
    
    this.columnDefs = [
      {
        headerName:"S.No",
        //   children:[
        // {          
        field: 'select',
        maxWidth: 50,
        checkboxSelection: true,
       headerCheckboxSelection: true,
      },
      {  headerName: "Email Id",field: 'emailid', sortable: true },     
      { headerName: "First Name",field: 'firstname', sortable: true, filter: true },
      // {  headerName: "Local Authority Desc", field: 'localauthoritydesc', sortable: true, filter: true },   
      {  headerName: "Last Name",field: 'lastname', sortable: true, filter: true },     
      {  headerName: "QRT Role",field: 'QRT_Role', sortable: true, filter: true,wrap:true      
      //cellRenderer: this.ageCellRendererFunc
      // cellRenderer: function(params:any) {     
      //   let qtype=params.data.QRT_Role; 
      //   let keyData = params.value; 
      //  console.log(keyData);
      //   let newLink:any;
      //   newLink=`<button ng-click=ChangeRole(QRT_Role,${keyData})>${keyData}</button>`;
      //     //  newLink=`<a href= https://llcmigration-arcgis-dev.cyienteurope.com/portal/apps/webappviewer/index.html?id=cee1c913bc844d2d9bbfb0bc2171033b&find=${keyData}
      //     //  target="_blank"><mat-icon class="mat-icon material-icons mat-icon-no-color" role="img" aria-hidden="true">
      //     //  map</mat-icon></a>`;               
      //   return newLink;
      // }
      },
      {  headerName: "TQT Role",field: 'QFT_Role', sortable: true, filter: true },
      {  headerName: "WAT Role",field: 'WFM_Role', sortable: true, filter: true },
      {  headerName: "PDQ Role",field: 'PDQ_Role', sortable: true, filter: true },
      {  headerName: "MI Role",field: 'MIDashboard_Role', sortable: true, filter: true },
      {  headerName: "AGS Role",field: 'AGS_Role', sortable: true, filter: true },
      {  headerName: "Compass Role",field: 'Compass_Role', sortable: true, filter: true },
      {  headerName: "FME Role",field: 'FME_Role', sortable: true, filter: true,wrap:true  }
    
    //],
    //}
    ];
    
    this.defaultColDef = {     
      sortable: true,     
      wrap:true,
      filter: true,
      // flex: 2,
      minWidth: 50,
      maxwidth:100,
      resizable: true
      //,editable:true    
    }; 
   }
   
  // ngOndestroy() {
  //   localStorage.setItem('FromRouter','Assignroles');
  // }
  ngOnInit(): void {
    localStorage.setItem('Status','');
  }
  reload(){
    this.router.navigate([this.router.url])
  }
  onSelectionChanged(event: any) { 
    //alert("selection");    
    let rowsSelection = this.gridApi.getSelectedNodes();
    if(rowsSelection.length>0)
    {
      this.isUserSelected=true;
      this.selectedObj=rowsSelection[0].data;
    }
    else
    {
      this.isUserSelected=false;
      this.selectedObj=null;
    }   
    
  }
  
  onPaginationChanged(params:any)
  {
    //alert('hi');
    //this.gridApi.pagination=true;
  }
  Add_UpdateUser():void{
   //alert(this.isUserSelected);
   this.obj=this.selectedObj;
   //this.obj.action='Add';
   console.log(this.obj);
   this.openDialog('Add',this.obj);
 }
 Delete_User():void{
   if(this.selectedObj==null)
   {
    this._snackBar.open("Select a User to delete", "OK",{"duration": 2000,panelClass: ['red-snackbar']}  );              
   }
   else{
     //alert("deletion function");
     this.obj=this.selectedObj;
     this.service.deleteClampUser(this.obj)
     .subscribe(       
      (data:any)=>{
       console.log('deleted user',data); 
       if(data && data.Deleted.toString()=='1')   
       {
        this._snackBar.open("Deleted User Successfully", "OK",{"duration": 2000,panelClass: ['red-snackbar']}  );              
        this.getDataFromAPI();
       }  
       else
       {
        this._snackBar.open("User doesn't exist. Please try again later!!!", "OK",{"duration": 2000,panelClass: ['red-snackbar']}  );              
        this.getDataFromAPI();
       }     
      }); 
      this.isUserSelected=false;
   }
 }
 
 openDialog(action:any,obj:any) {
 
 let newobj={
   obj:obj,
   action:action
 } 
 const dialogRef = this.matDialog.open(NewUsermodalComponent, {
  width: '700px',
  height:'600px',
   data:obj
 });

 dialogRef.afterClosed().subscribe(result => {
   
     //this.getDataFromAPI();
     this.isUserSelected=false;
     this.selectedObj=null;
    // this.addRowData(result.data); 
    this.Refresh(); 
    console.log("AddedUser: "+localStorage.getItem('AddedUserId'));
    if(!localStorage.getItem('AddedUserId'))
    {
    setTimeout(() => {
    if(localStorage.getItem('AddedUserId') && localStorage.getItem('AddedUserId')!='')
    {
      var xhr1 = new XMLHttpRequest();
      let graphurl1=graphAPIUrl+'/groups/'+ADGroup_OID+'/members/$ref'; 
      //console.log("accesstoken_Group: "+localStorage.getItem('accesstoken_Group'))
      xhr1.open("POST", graphurl1, true);    
      xhr1.setRequestHeader('Authorization',"Bearer "+localStorage.getItem('accesstoken'));
      xhr1.setRequestHeader('Content-Type', 'application/json');
      console.log("Adding to group"); 
      console.log('@odata.id: https://graph.microsoft.com/v1.0/directoryObjects/'+localStorage.getItem('AddedUserId'));
      xhr1.onload = function () {
        console.log(xhr1.status);
      var data1 = this.responseText;      
      console.log("onload in ADDgroup"); 
        console.log(data1);
        if(xhr1.status==204)
        {
          console.log("xhr1.status"+xhr1.status);
          var s=localStorage.getItem('Status');
          localStorage.setItem('Status',s+'@Assigned Group'); 
          console.log(localStorage.getItem('Status')); 
        }
        else{
          console.log("Error in ADDGroup");
          console.log();
          var s=localStorage.getItem('Status');
          var ww=JSON.parse(this.responseText);
          console.log(ww.error.message);
          localStorage.setItem('Status',s+"@Error_in_ADDGroup: "+ww.error.message); 
          console.log(localStorage.getItem('Status'));
        }
          var xhr2 = new XMLHttpRequest();
          //let urlWeb=`http://localhost:3001/api/updateAzureADstatus`;  
          let urlWeb=`${baseUrl}/updateAzureADstatus`; 
          var params = ""+localStorage.getItem('emailid')+"/"+localStorage.getItem('Status');        
          
          //console.log("accesstoken_Group: "+localStorage.getItem('accesstoken_Group'))
          
          //xhr2.open("POST", urlWeb, true);    
          xhr2.open("GET", urlWeb+"/"+params, true);    
                            
          xhr2.onload = function () {
            console.log(xhr2.status);
            //var data2 = this.responseText;
            var data2 = JSON.parse(this.responseText)
            console.log("updated in Userlist table");
            console.log(data2.Newid);            
            localStorage.setItem('inviteRedeemURL','');
            localStorage.setItem('emailid','');
            localStorage.setItem('firstname','');
            localStorage.setItem('lastname','');
            localStorage.setItem('AddedUserId','');
            //localStorage.setItem('Status','');
          }
          xhr2.onerror = function () {
            console.log("error");
          };
          // console.log(JSON.stringify({
          //   "emailid":""+localStorage.getItem('emailid'),
          //   "status":""+localStorage.getItem('Status')
          // }));
          xhr2.send(null);        
        //this.GetUserID(arg);    
      };
      
      xhr1.onerror = function () {
      console.log("error");
      };
      xhr1.send(JSON.stringify({
        "@odata.id": "https://graph.microsoft.com/v1.0/directoryObjects/"+localStorage.getItem('AddedUserId')
        //"@odata.id": "https://graph.microsoft.com/v1.0/directoryObjects/d9101576-eab0-4ac0-9f42-a8ec7fa7ae34"        
      }));
      localStorage.setItem('AddedUserId','');
    } 
    }, 10000); 
    setTimeout(() => {
      console.log("settimeout: 11000");
      var statustodisplay=''+localStorage.getItem('Status');
      console.log(statustodisplay);
      if(statustodisplay=='')
      {
        return;
      }
      //"@ClampList_Success@Internal_User"
      if(statustodisplay.includes("@ClampList_Success@Internal_User"))
      {
        this._snackBar.open("Group Assigned to user", "OK",{"duration": 2000,panelClass: ['red-snackbar']}  );              
      }
      //"@ClampList_Success@Invitation Sent@ Email Sent@ Assigned Group"
      else if(statustodisplay.includes('@Invitation Sent@Email Sent@Assigned Group'))
      {
        this._snackBar.open("User Created and group Assigned", "OK",{"duration": 2000,panelClass: ['red-snackbar']}  );              
      }
      else if(statustodisplay.includes('Error'))
      {
        //alert(statustodisplay);
        //"@Error_in_ADDGroup: Resource '121800a8-0d97-4be9-8419-5b689f78b93b' does not exist or one of its queried reference-property objects are not present."
        if(statustodisplay.includes("Error_in_ADDGroup"))
        {
          this._snackBar.open("User created, adding to group failed! Please contact administrator.", "OK",{"duration": 3000,panelClass: ['red-snackbar']}  ); 
        }        
        else if(statustodisplay.includes("Error_in_Invite"))
        {
          this._snackBar.open("User Creation failed. Please login and try again!!", "",{"duration": 3000,panelClass: ['red-snackbar']}  ); 
        }          
        else
        {
          this._snackBar.open("User Creation failed. Please try again!!.", "",{"duration": 3000,panelClass: ['red-snackbar']}  ); 
        }
      }
      console.log(localStorage.getItem('Status'));
      localStorage.setItem('Status','');
    }, 11000); 
    }
 });
 

}
  onGridReady(params:any) {
  
    this.gridApi = params.api;  
    this.gridColumnApi = params.columnApi;
    params.api.showLoadingOverlay();
    setTimeout(() => {
  this.getDataFromAPI();
    },500);
  }
  ngAfterViewInit()  {
    // alert('Afterviewoninit');
      //  if( this.dataSource){
      //    this.dataSource.paginator = this.paginator;
      //    this.dataSource.sort = this.sort;
      //    }
  }

  //Display all Jobs
  getDataFromAPI(){  
     // alert(param);
     // console.log('input',param)

     this.service.getAllClampUsersList()
     .subscribe(       
      data=>{     
       console.log('data of jobs',data);
       this.rowData=data[0]; 
       this.roleslist=data[1]; 
      //  this.dataSource=new MatTableDataSource(data[0]);
      //    this.dataSource.paginator = this.paginator;
      //  this.dataSource.sort = this.sort;
      });    
   
  }

  _setDataSource(indexNumber:any) {
    setTimeout(() => {
      switch (indexNumber) {
        case 0:
          this.tabid="jobtab";     
          break;
        case 1:
          this.tabid="qctab";       
          break;
        case 2:
          this.tabid="qatab";        
          break;
      }
    });
  }
  Refresh()
  {   
    this.getDataFromAPI();
  }
  importExcel(){
    this.service.getAllClampUsersList()
     .subscribe(       
      data=>{     
       console.log('user details',data);
       //this.rowData=data[0]; 
       this.importexceldata(data[0]);
      
      });  
  }
  
  //import excel function inside
  importexceldata(data:any)
  {
    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(data)
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Users List'); 
    const now = new Date();
    console.log('now',now);
    XLSX.writeFile(wb, 'ClampUsers_'+now+'.xlsx');
  }
 
}
