import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material-module';
import { JobScheduleComponent } from './job-schedule/job-schedule.component';
import { PopupComponent } from './job-schedule/popup/popup.component';
import { SideNavDrawerComponent } from './side-nav-drawer/side-nav-drawer.component';
import { FormsModule,ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import { QueryBuilderModule } from "angular2-query-builder";
import { AgGridModule } from 'ag-grid-angular';
import { AgChartsAngularModule } from 'ag-charts-angular';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgMultiSelectDropDownModule, IDropdownSettings } from 'ng-multiselect-dropdown';

import{RouterModule} from '@angular/router';
import { CreateJobComponent } from './create-job/create-job.component';
import { UserComponent } from './user/user.component';
import { TemplatesComponent } from './templates/templates.component';
//import { HomeComponent } from './home/home.component';
import { TestjobComponent } from './testjob/testjob.component';
import { ReportsComponent } from './reports/reports.component';

import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { LogoutComponent } from './logout/logout.component';
import { TqtjobsComponent } from './tqtjobs/tqtjobs.component';
import { DatePipe } from '@angular/common';
import { MAT_DATE_LOCALE } from '@angular/material/core'
import { AssignRolesAdminComponent } from './assign-roles-admin/assign-roles-admin.component';

import { NewUsermodalComponent } from './assign-roles-admin/new-usermodal/new-usermodal.component';
import { QrtlareportComponent } from './qrtlareport/qrtlareport.component';
import { ExampleHeaderComponentComponent } from './reports/example-header-component/example-header-component.component';
import { CustomRangePanelComponent } from './reports/custom-range-panel/custom-range-panel.component';
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;


export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: '428822e7-5acb-4cf8-a991-62f362a89cfc',//QFT_app_4200
      authority: 'https://login.microsoftonline.com/2d6b0cf3-57fa-4619-abf9-d13e1ef2352a',     
      //authority: 'https://login.microsoftonline.com/common', // Prod environment. Uncomment to use.     
      redirectUri: 'https://llcmigration-wat.cyienteurope.com',// Prod environment. Uncomment to use.
     // redirectUri: 'http://localhost:3001/home',    
      //postLogoutRedirectUri: 'https://llcmigration-wat.cyienteurope.com/logout' //Prod Environment logout.. Uncomment to use.         
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); 
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/invitations', ['User.ReadWrite.All','User.Invite.All']); 
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/Users', ['user.read','User.Invite.All','User.Read.All','User.ReadWrite.All','Directory.Read.All', 'Directory.ReadWrite.All', 'Directory.AccessAsUser.All','GroupMember.ReadWrite.All','Group.ReadWrite.All']); 
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/groups', ['Directory.AccessAsUser.All', 'Directory.ReadWrite.All', 'Group.ReadWrite.All','GroupMember.ReadWrite.All']); 
  
  //protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect };
}

@NgModule({
  declarations: [
    AppComponent,
    JobScheduleComponent,
    SideNavDrawerComponent,
    CreateJobComponent,
    PopupComponent,
    UserComponent,
    TemplatesComponent,
    //HomeComponent,
    TestjobComponent,
    ReportsComponent,
    LogoutComponent,
    TqtjobsComponent,
    AssignRolesAdminComponent,    
    NewUsermodalComponent, QrtlareportComponent, ExampleHeaderComponentComponent, CustomRangePanelComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    QueryBuilderModule,
    AgGridModule.withComponents([]),
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MsalModule,
    AgChartsAngularModule ,
    NgMultiSelectDropDownModule 
  ],
  providers: [ {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  
  },
  {
    provide: MAT_DATE_LOCALE, useValue: 'en-GB'
  },
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory
  },
  MsalService,
  MsalGuard,
  MsalBroadcastService,
  DatePipe],
bootstrap: [AppComponent,MsalRedirectComponent]
})
export class AppModule { }
