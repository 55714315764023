<div class="headertoolbar_newuser">  
    <mat-nav-list style="display: flex;flex-direction: row;align-items: center;justify-content: center;">  
    <!--<mat-list-item> 
        <mat-form-field >
       <mat-label>Select User</mat-label>
        <mat-select [(ngModel)]="userid" (ngModelChange)='onUserSelected($event)'>
          <mat-option [value]="0" >Select All</mat-option>
              <mat-option *ngFor="let user of users"   [value]="user.emailid">
            {{user.emailid}}
          </mat-option>
        </mat-select> 
      </mat-form-field>
       </mat-list-item>-->
          <!-- <mat-list-item>
            <button mat-raised-button (click)="Add_UpdateUser();"><span *ngIf="isUserSelected" >Update User</span><span *ngIf="!isUserSelected" >Add User</span></button>                        
          </mat-list-item>          -->
          <mat-list-item> 
            <button mat-raised-button (click)="importExcel()">Export to Excel</button>            
          </mat-list-item>
          <mat-list-item> 
            <button mat-raised-button (click)="Refresh()">Refresh</button>                
          </mat-list-item>
     </mat-nav-list>
</div>
<br/>
<div>
    <!-- <h3 style=float:right;padding-right:200px;>{{latext}}</h3> -->
     <mat-tab-group (selectedIndexChange)="_setDataSource($event)"  dynamicHeight=5%>
     
        <mat-tab>
          <ng-template mat-tab-label>
            <!-- <mat-icon class="example-tab-icon">supervised_user_circle</mat-icon> -->
            LA Summary
          </ng-template>     
          <div class="grid-wrapper">  
              <!-- style="width:100vw;height:80vh;" -->       
          <ag-grid-angular         
            class="ag-theme-alpine"        
            #agGridSummary            
            id="agGridSummary"
            class="ag-theme-alpine"
            [columnDefs]="columnDefs_Sum"
            [defaultColDef]="defaultColDef_Sum"
            [rowData]="rowDataSummary"
             [pagination]="true"    
             [paginationPageSize]="20"      
            (gridReady)="onGridReadySummary($event)"            
            [overlayLoadingTemplate]="loadingTemplate"
            [overlayNoRowsTemplate]="noRowsTemplate"
            >
          </ag-grid-angular> 
         </div>    
        </mat-tab>
      
        <mat-tab>
          <ng-template mat-tab-label>
            <!-- <mat-icon class="example-tab-icon">verified_user</mat-icon> -->
            Charges at LA with Outstanding days
          </ng-template>
          <div class="grid-wrapper">
          <ag-grid-angular         
          class="ag-theme-alpine"        
          #agGridOutstanding
          style="width:100%; height:100%;"
          id="agGridOutstanding"
          class="ag-theme-alpine"
          [columnDefs]="columnDefs_Out"
          [defaultColDef]="defaultColDef_Out"
          [rowData]="rowDataOutstanding"
           [pagination]="true"         
           (gridReady)="onGridReadyOutstanding($event)">
        </ag-grid-angular>
        </div>
        </mat-tab>    
      </mat-tab-group> 
  </div>