<mat-card class="example-card">
    <mat-card-header>
     
      <mat-toolbar>Create Job</mat-toolbar>
      
    </mat-card-header>
<mat-card-content>
<div class="col-sm-6">
    <form [formGroup]="newJobForm">
        <div class="form-group">
            <div class="example-container">

                <mat-form-field appearance="outline">
                    <mat-label>Job Id</mat-label>
                    <input matInput class="form-control" formControlName="jobId" placeholder="Job Id">

                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Job Description</mat-label>
                    <input matInput class="form-control" formControlName="jobDesc" placeholder="Job Description">

                </mat-form-field>


                <mat-form-field appearance="outline">
                    <mat-label>Local Authority</mat-label>
                    <mat-select class="form-control" formControlName="lacode" placeholder="Local Description">
                        <mat-option *ngFor="let authority of localAuthorities" [value]=authority.id>{{authority.value}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <button type="submit" mat-raised-button color="primary"  [disabled]="!newJobForm.valid" style="width:10%;margin-top: 1%;"(click)="onSubmit()">Save</button>

            </div>
        </div>

    </form>

</div>
</mat-card-content>
</mat-card>