<div class="alignCenter" >
  
  <form [formGroup]="userform" ngForm style="margin-top:0px!important;">
      
          <h5 *ngIf="!isExistuser" style="text-align: center;color : #4eb8c2; font-size: medium; font-weight: 600;">Add User Details</h5>            
          <h5 *ngIf="isExistuser" style="text-align: center;color : #4eb8c2; font-size: medium; font-weight: 600;">Update User Details</h5>            
           
      <div style="margin-top:0px;text-align: left !important;">
        <mat-radio-group layout="row" style="margin-top:0px;text-align: left !important;"
          aria-labelledby="example-radio-group-label"
          class="example-radio-group customradio" 
          [(ngModel)]="checkInternalUser"  [ngModelOptions]="{standalone: true}">
          <mat-radio-button style="padding-inline-end: 42px;" class="example-radio-button" *ngFor="let type of Usertypes" [value]="type" (change)="radioChange($event)">
              {{type}}
          </mat-radio-button>
        </mat-radio-group>
      </div> 
     
      <div class="myclass">              
            <span>Local Authority</span>  
            <!-- [(ngModel)]="selectedItemsObj"  -->
            <ng-multiselect-dropdown class="multiselectcls" style="padding-top:5px;height:20%;text-align:left !important;" #multiSelect                  
            [placeholder]="'Select'"                   
            formControlName="LAnames"
            [data]="dataLA"  
            [disabled]="isInternalUser"                                              
            [settings]="dropdownSettings"                             
            (onFilterChange)="onFilterChange($event)"
            (onDropDownClose)="onDropDownClose($event)"
            (onSelect)="onItemSelect($event)" 
            (onDeSelect)="onDeSelect($event)"
            (onSelectAll)="onSelectAll($event)"
            (onDeSelectAll)="onDeSelectAll($event)">                  
          </ng-multiselect-dropdown>
          
              
      </div>
      
      <div class="row myclass">         
        <div class="col">   
          <label for="colFormLabelSm">First Name</label> 
          <input class="form-control form-control-sm" type="text" id="firstname" formControlName="firstname" >
     
          <!-- <span class="required-asterisk">*</span>                    
          <select class="form-control form-control-sm" formControlName="localauth" value="localauth" id="localauth" required>
            <option value="0">--Select--</option>
            <option value="{{option.Local_Authority}}" *ngFor="let option of local_authorities_list;let i = index">{{option.Local_Authority}}</option>
        </select> -->
        </div>
        <div class="col"> 
          <label for="colFormLabelSm">Last Name</label>
          <input class="form-control form-control-sm" type="text" id="lastname" formControlName="lastname" >
        </div>        
</div>
      <div class="myclass">
        <label for="colFormLabelSm">Email Id</label>
        <input *ngIf="!isExistuser" class="form-control form-control-sm" type="text" id="emailid" formControlName="emailid">
        <input *ngIf="isExistuser" class="form-control form-control-sm" type="text" id="emailid" formControlName="emailid" readonly>
      </div>
     
        <div class="row myclass">                
          <div class="col">   
            <label for="colFormLabelSm">QRT Role</label>              
            <select class="form-control form-control-sm" formControlName="QRT_Role" value="QRT_Role" >
              <option value="0">--Select--</option>
              <option value="{{option}}" *ngFor="let option of qrt_roles;let i = index">{{option}}</option>
          </select>
          </div>                        
          <div class="col">   
              <label for="colFormLabelSm">TQT Role</label>              
              <select class="form-control form-control-sm" formControlName="TQT_Role" value="TQT_Role"  >
                <option value="0">--Select--</option>
                <option value="{{option}}" *ngFor="let option of tqt_roles;let i = index">{{option}}</option>
            </select>
            </div>
                
        </div>
        <div class="row myclass">                
          <div class="col">   
            <label for="colFormLabelSm">PDQ Role</label>              
            <select class="form-control form-control-sm" formControlName="PDQ_Role" value="PDQ_Role"  >
              <option value="0">--Select--</option>
              <option value="{{option}}" *ngFor="let option of pdq_roles;let i = index">{{option}}</option>
          </select>
          </div>          
          <div class="col">   
              <label for="colFormLabelSm">WAT Role</label>              
              <select class="form-control form-control-sm" formControlName="WAT_Role" value="WAT_Role" id="WAT_Role" >
                <option value="0">--Select--</option>
                <option value="{{option}}" *ngFor="let option of wat_roles;let i = index">{{option}}</option>
            </select>
            </div>
            </div>
            <div class="row myclass">
          <div class="col">   
            <label for="colFormLabelSm">MI Dashboard Role</label>              
            <select class="form-control form-control-sm" formControlName="MIDashboard_Role" value="MIDashboard_Role" id="MIDashboard_Role" >
              <option value="0">--Select--</option>
              <option value="{{option}}" *ngFor="let option of midash_roles;let i = index">{{option}}</option>
          </select>
          </div>                        
          <div class="col">   
            <label for="colFormLabelSm">ArcGIS Sweet Role</label>              
            <select class="form-control form-control-sm" formControlName="AGS_Role" value="AGS_Role" id="AGS_Role" >
              <option value="0">--Select--</option>
              <option value="{{option}}" *ngFor="let option of ags_roles;let i = index">{{option}}</option>
          </select>
          </div>        
        </div>
        <div class="row myclass">                
                             
          <div class="col">   
              <label for="colFormLabelSm">COMPASS Role</label>              
              <select class="form-control form-control-sm" formControlName="COM_Role" value="COM_Role" id="COM_Role" >
                <option value="0">--Select--</option>
                <option value="{{option}}" *ngFor="let option of com_roles;let i = index">{{option}}</option>
            </select>
            </div>
                  
          <div class="col">   
            <label for="colFormLabelSm">FME Role</label>              
            <select class="form-control form-control-sm" formControlName="FME_Role" value="FME_Role" id="FME_Role" >
              <option value="0">--Select--</option>
              <option value="{{option}}" *ngFor="let option of fme_roles;let i = index">{{option}}</option>
          </select>
          </div>                        
                 
        </div>
       
      
          <div style="padding-top: 30px;" class="myclass">
            
          <button mat-raised-button  (click)="Add_UpdateUser()" style="margin-right: 10px; font-size: 18px!important;font-weight: bold!important;">Submit            
          </button>
          <button mat-raised-button type="button" *ngIf="!isExistuser" class="btn add_lp" (click)="AddandInviteUserwithEmail()" style="margin-right: 10px; font-size: 18px!important;font-weight: bold!important;" >Invite User and send Email                  
          </button>
          <button mat-raised-button  type="button" class="btn add_lp" style=" font-size: 18px!important;font-weight: bold!important;" (click)="reset()">Cancel</button>          
          </div>
          
    
  </form>  
</div>    
  

<!-- <div class="col-md-12 col-sm-10 col-md-8 col-sm-offset-1 col-sm-offset-2">
     
</div> -->
