
import { Component, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import{AppServiceService} from '../app-service.service';
import { HttpClient,HttpParams } from '@angular/common/http';
import { ColDef } from 'ag-grid-community';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent {
 // agGrid:any;
   gridApi:any;
   gridColumnApi:any;

   columnDefs:any;
   defaultColDef:any;
  rowData: [];

  constructor(private service:AppServiceService,private http: HttpClient) {
   // console.log('aggrid',this.agGrid);
    this.columnDefs = [
      { field: 'jobid', sortable: true, filter: true,checkboxSelection:true },
      { field: 'jobdesc', sortable: true, filter: true },
      { field: 'assignedto', sortable: true, filter: true },   
      { field: 'jobstatus', sortable: true, filter: true },
      { field: 'localauthoritydesc', sortable: true, filter: true },
      { field: 'localauthoritycode', sortable: true, filter: true }
    ];
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
    };
  


  //  const gridOptions = {
  //   columnDefs: [
  //     { field: 'jobid', sortable: true, filter: true,checkboxSelection:true },
  //   { field: 'jobdesc', sortable: true, filter: true },
  //   { field: 'assignedto', sortable: true, filter: true },   
  //   { field: 'jobstatus', sortable: true, filter: true },
  //   { field: 'localauthoritydesc', sortable: true, filter: true },
  //   { field: 'localauthoritycode', sortable: true, filter: true }
  //   ],
  //   defaultColDef: {
  //     flex: 1,
  //     minWidth: 100,
  //   },
  //   rowSelection: 'multiple'
   
  // };
}

  getSelectedRowData() {
    let selectedNodes = this.gridApi.getSelectedNodes();
    //console.log('test',this.gridApi.getSelectedNodes())
    let selectedData = selectedNodes.map((node:any)=> node.data);
    //alert(`Selected Nodes:\n${JSON.stringify(selectedData)}`);
    return selectedData;
  }
  onGridReady(params:any) {
    this.gridApi = params.api;
    
    this.gridColumnApi = params.columnApi;

    this.service.getAllJobs()

    .subscribe(
      
     data=>{     
      this.rowData=data;
  
      });
  }

}

 
  