
    
     <mat-toolbar *ngIf="action != 'Querybuilder' && action != 'Close' ">Confirm </mat-toolbar>
     <mat-toolbar *ngIf="action == 'Querybuilder'">Build your own Query-QueryBuilder</mat-toolbar>
 
<!-- <h1 mat-dialog-title>  {{action}}</h1> -->
<div mat-dialog-content >
  <div *ngIf="action != 'Delete'; else elseTemplate">
</div>
  <ng-template #elseTemplate>
    Are you sure to delete jobs {{local_data.jobid}}?
  </ng-template>
  <div *ngIf="action == 'Re assign'">
    Are you sure to Re assign job?
  </div>
  <div *ngIf="action == 'ok'">
    Please choose user to assign first
  </div>

  <div *ngIf="action == 'Querybuilder'">
    <query-builder [(ngModel)]='query' [config]='config'>
      <ng-container *queryButtonGroup="let ruleset; let addRule=addRule; let addRuleSet=addRuleSet; let removeRuleSet=removeRuleSet">
        <button type="button" mat-button (click)="addRule()">+ Rule</button>
        <button type="button" mat-button (click)="addRuleSet()">+ Ruleset</button>
        <button type="button" mat-button (click)="removeRuleSet()">- Ruleset</button>
      </ng-container>
      <ng-container *queryRemoveButton="let rule; let removeRule=removeRule">
        <button type="button" mat-icon-button color="accent" (click)="removeRule(rule)">
          <mat-icon>remove</mat-icon>
        </button>
      </ng-container>
      <ng-container *querySwitchGroup="let ruleset">
        <mat-radio-group *ngIf="ruleset" [(ngModel)]="ruleset.condition">
          <mat-radio-button value="and">And</mat-radio-button>
          <mat-radio-button value="or">Or</mat-radio-button>
        </mat-radio-group>
      </ng-container>
      <ng-container *queryField="let rule; let fields=fields; let onChange=onChange">
        <mat-form-field>
          <mat-select [(ngModel)]="rule.field" (ngModelChange)="onChange($event, rule)">
            <mat-option *ngFor="let field of fields" [value]="field.value">{{field.name}}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <ng-container *queryOperator="let rule; let operators=operators">
        <mat-form-field>
          <mat-select [(ngModel)]="rule.operator">
            <mat-option *ngFor="let value of operators" [value]="value">{{value}}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>
      <!-- Override input component for 'boolean' type -->
      <ng-container *queryInput="let rule; type: 'boolean'">
        <mat-checkbox [(ngModel)]="rule.value"></mat-checkbox>
      </ng-container>
      <!-- Override input component for 'category' type -->
    
    </query-builder>
  </div>


  <div *ngIf="action == 'okay'">
    Jobs Assigned Successfully
  </div>
  <div *ngIf="action == 'Close'" class="divClose">
    You Dont Have Access To This Application!!
  </div>
</div>


<div mat-dialog-actions>
  <button mat-button (click)="doAction()">{{action}}</button>
  <button  *ngIf="action != 'Close'" mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancel</button>
</div>
