import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {JobScheduleComponent} from './job-schedule/job-schedule.component';
import {TqtjobsComponent} from './tqtjobs/tqtjobs.component';
import {CreateJobComponent} from './create-job/create-job.component'
import { UserComponent } from './user/user.component';
import { TemplatesComponent } from './templates/templates.component';
//import { HomeComponent } from './home/home.component';
import {TestjobComponent} from './testjob/testjob.component';
import { ReportsComponent } from './reports/reports.component';
import { LogoutComponent } from './logout/logout.component';
import { AssignRolesAdminComponent } from './assign-roles-admin/assign-roles-admin.component';
import { QrtlareportComponent } from './qrtlareport/qrtlareport.component';
const isIframe = window !== window.parent && !window.opener;
const routes: Routes = [
  { path: 'home',
  loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  // { path:'**',
  // component:HomeComponent},
  {
    path:'job',
    component:JobScheduleComponent
  },
  {
    path:'logout',
    component:LogoutComponent
  },
  // {
  //   path:'home',
  //   component:HomeComponent
  // },
  // {
  //   path:'',
  //   component:HomeComponent
  // },
 
  {
    path:'user',
    component:UserComponent
  },
  {
    path:'dashboard',
    component:TestjobComponent
  },
  {
    path:'reports',
    component:ReportsComponent
  },
  {
    path:'templates',
    component:TemplatesComponent
  },
  {
    path:'tqtjobs',
    component:TqtjobsComponent
  },
  {
    path:'createJob',
    component: CreateJobComponent  },
    {
      path:'assignroles',
      component:AssignRolesAdminComponent
    },
    {
      path:'qrtlareport',
      component:QrtlareportComponent
    }
];
// @NgModule({
//   imports: [RouterModule.forRoot(routes,
//      {useHash: true,
//       // Don't perform initial navigation in iframes
//       initialNavigation: !isIframe ? 'enabled' : 'disabled'})],
//       exports: [RouterModule]})
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents=[JobScheduleComponent,CreateJobComponent,UserComponent,TemplatesComponent]