import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import  {Observable} from 'rxjs';

import { AppServiceService } from 'src/app/app-service.service';
import { environment } from 'src/environments/environment';

import {  IDropdownSettings, } from 'ng-multiselect-dropdown';
import { isIdentifier } from '@angular/compiler';

const baseUrl=environment.baseUrl;
const graphAPIUrl=environment.graphAPIUrl;
const webURL=environment.WebUrl;
const ADGroup_OID=environment.ADGroup_OID;

export interface UserData {
  ID:number;
  emailid: string;
  firstname: string;
  lastname: string;
  QRT_Role: string;
  QFT_Role: string;
  WFM_Role: string;
  PDQ_Role: string;
  MIDashboard_Role: string;
  AGS_Role: string;
  FME_Role: string;
  COM_Role: string;
  LAnames:[];
  app_names:string;
  app_links:string;
  inviteRedeemURL:string;
  isInternalUser:boolean;
}
export interface LocalAuthorities {
  localauthoritydesc: string;
  localauthoritycode: string;
}

@Component({
  selector: 'app-new-usermodal',
  templateUrl: './new-usermodal.component.html',
  styleUrls: ['./new-usermodal.component.scss']
})

export class NewUsermodalComponent implements OnInit {

  checkInternalUser: string;
  isInternalUser:boolean;
  Usertypes: string[] = ['Internal', 'External'];
  
  //userform!: FormGroup;
  user_data:any;
  userform: any; 
  isExistuser:boolean; 
  isEmailtobeSent:boolean;
  role_list:[{
    RoleId:number;
    Role:string;
  }];
  graphAPI_userobj:any;
  qrt_roles:Array<string>=[];
  //qrt_roles:[string];
  tqt_roles:Array<string>=[];
  wat_roles:Array<string>=[];
  pdq_roles:Array<string>=[];
  midash_roles:Array<string>=[];
  fme_roles:Array<string>=[];
  com_roles:Array<string>=[];
  ags_roles:Array<string>=[];
  isSandhya:boolean=false;

  dropdownList :Array<LocalAuthorities>=[];
  dropdownSettings:IDropdownSettings={};
  public dataLA = [];
  public settings = {};
  selectedItemsObj:Array<LocalAuthorities>=[];
  @ViewChild('multiSelect') multiSelect: any;
  
  // qrt_roles:[{
  //   RoleId:number;
  //   Role:string;
  // }];
  // tqt_roles:[{
  //   RoleId:number;
  //   Role:string;
  // }];
  // wat_roles:[{
  //   RoleId:number;
  //   Role:string;
  // }];
  // pdq_roles:[{
  //   RoleId:number;
  //   Role:string;
  // }];
  // midash_roles:[{
  //   RoleId:number;
  //   Role:string;
  // }];

  constructor(private formBuilder: FormBuilder,private router: Router,private http:HttpClient,private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<NewUsermodalComponent>,private service:AppServiceService,
    @Optional() 
    //is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: UserData) {
    console.log(data);
    this.user_data = {...data};
    console.log('data here');
    this.dropdownSettings = {
      idField: 'localauthoritycode',
      textField: 'localauthoritydesc',
      itemsShowLimit: 2,
      // allowSearchFilter: true
    };
     // setting and support i18n
     this.settings = {
      singleSelection: false,
      idField: 'localauthoritycode',
      textField: 'localauthoritydesc',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 197,
      itemsShowLimit: 3,
      searchPlaceholderText: 'Local Authority',
      noDataAvailablePlaceholderText: 'No data',
      closeDropDownOnSelection: true,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
    };
    if(sessionStorage.getItem('useremailid')=='Sandhya.Tatikonda@cyient.com' ||sessionStorage.getItem('useremailid')=='Surender.Karanam@cyient.com')
    {
      this.isSandhya=true;
    }
    
  
  }

  ngOnInit(): void {
    //this.checkInternalUser="Valid Referral"; 
    this.InitializeForm();
    //this.getUserRoles();
    this.service.getLocalauthorities()
    .subscribe(
     data=>{
       //this.options=data;
       //console.log('data', this.options);
       console.log("LA data");
       this.dropdownList=data;  
       this.dataLA=data; 
     
     }); 
    if(this.user_data.emailid && this.user_data.emailid!='')
     {
     this.service.getLAnamesbyUsermeailid(this.user_data.emailid)
    .subscribe(
     data=>{
       //this.options=data;
       console.log('LA by usermailid: ', data);
       //this.selectedItems=data[0];   
       for (let index = 0; index < data[0].length; index++) {
         const element = data[0][index];        
         this.selectedItemsObj.push(element);
         //this.selectedItems.push(element.localauthoritydesc);         
       }
       console.log(this.selectedItemsObj)    
       this.userform.patchValue({ 
        LAnames: this.selectedItemsObj});
       //alert(this.selectedItemsObj);     
     }); 
    }
    
  }
  radioChange(event:any)
  {
    //alert(event);
    //console.log(event);
    if(event.value=="Internal")
    {
      this.isInternalUser=true;
      this.userform.patchValue({
        LAnames:null
      })
    }
    else if(event.value=="External")
    {
      this.isInternalUser=false;      
    }
    //alert(this.isInternalUser);
  }
  InitializeForm():void{
    if(this.user_data!=null && this.user_data.emailid!=undefined && this.user_data.emailid!='')
    {
      this.isExistuser=true;
      if(this.user_data.emailid.includes('@cyient.com'))
      {
        this.isInternalUser=true;
        this.checkInternalUser='Internal'
        //alert("includes");
      }
      else
      {
        this.isInternalUser=false;this.checkInternalUser='External';
        //alert("not includes");
      }
      this.userform = this.formBuilder.group({
        emailid: new FormControl(''),
        firstname:new FormControl(''),
        lastname:new FormControl(''),
        WAT_Role:new FormControl(''),
        WAT_Roleid:new FormControl(Number),
        QRT_Role:new FormControl(''),
        QRT_Roleid:new FormControl(Number),
        TQT_Role:new FormControl(''),
        TQT_Roleid:new FormControl(Number),
        PDQ_Role:new FormControl(''),
        PDQ_Roleid:new FormControl(Number),
        MIDashboard_Role:new FormControl(''),
        MIDashboard_Roleid:new FormControl(Number),
        FME_Role:new FormControl(''),
        FME_Roleid:new FormControl(Number),
        AGS_Role:new FormControl(''),
        AGS_Roleid:new FormControl(Number),
        COM_Role:new FormControl(''),
        COM_Roleid:new FormControl(Number),
        LAnames:new FormControl([]),
        isInternalUser:new FormControl(Boolean),
        isActive:new FormControl(Boolean)
      });
    }
    else{
      //
      this.isExistuser=false;
     // alert('hi');
      this.userform = this.formBuilder.group({
        emailid: new FormControl(''),
        //emailid: new FormControl({value:'',disabled: true}),
        firstname:new FormControl(''),
        lastname:new FormControl(''),
        WAT_Role:new FormControl(''),
        WAT_Roleid:new FormControl(Number),
        QRT_Role:new FormControl(''),
        QRT_Roleid:new FormControl(Number),
        TQT_Role:new FormControl(''),
        TQT_Roleid:new FormControl(Number),
        PDQ_Role:new FormControl(''),
        PDQ_Roleid:new FormControl(Number),
        MIDashboard_Role:new FormControl(''),
        MIDashboard_Roleid:new FormControl(Number),
        FME_Role:new FormControl(''),
        FME_Roleid:new FormControl(Number),
        AGS_Role:new FormControl(''),
        AGS_Roleid:new FormControl(Number),
        COM_Role:new FormControl(''),
        COM_Roleid:new FormControl(Number),
        LAnames:new FormControl([]),
        isInternalUser:new FormControl(Boolean),
        isActive:new FormControl(Boolean)
      });
    }
  this.getUserRoles();
  if(this.user_data)
  {
    //alert("not null");
    this.userform.patchValue({ 
      emailid: this.user_data.emailid,
        firstname:this.user_data.firstname,
        lastname:this.user_data.lastname,
        WAT_Role:this.user_data.WFM_Role,
        QRT_Role:this.user_data.QRT_Role,
        //QRT_Roleid:this.user_data.emailid,
        TQT_Role:this.user_data.QFT_Role,
        //TQT_Roleid:this.user_data.emailid,
        PDQ_Role:this.user_data.PDQ_Role,
        //PDQ_Roleid:this.user_data.emailid,
        MIDashboard_Role:this.user_data.MIDashboard_Role,
        FME_Role:this.user_data.FME_Role,
        //FME_Roleid:this.user_data.emailid,
        AGS_Role:this.user_data.AGS_Role,
        //AGS_Roleid:this.user_data.emailid,
        COM_Role:this.user_data.COM_Role,
        //COM_Roleid:this.user_data.emailid,
        LAnames:this.user_data.LAnames,
        //isInternalUser:true,
        //MIDashboard_Roleid:this.user_data.emailid        
    });
  
    // if(this.user_data.emailid.includes('cyient.com'))
    // {
    //   this.checkInternalUser='Internal';
    //   this.userform.patchValue({ 
    //     emailid: this.user_data.emailid,
    //       firstname:this.user_data.firstname,
    //       lastname:this.user_data.lastname,
    //       WAT_Role:this.user_data.WFM_Role,
    //       QRT_Role:this.user_data.QRT_Role,
    //       //QRT_Roleid:this.user_data.emailid,
    //       TQT_Role:this.user_data.QFT_Role,
    //       //TQT_Roleid:this.user_data.emailid,
    //       PDQ_Role:this.user_data.PDQ_Role,
    //       //PDQ_Roleid:this.user_data.emailid,
    //       MIDashboard_Role:this.user_data.MIDashboard_Role,
    //       LAnames:this.user_data.LAnames,
    //       //isInternalUser:true,
    //       //MIDashboard_Roleid:this.user_data.emailid
          
    //   });
    // }
    // else
    // {
    //   this.checkInternalUser='External';
    //   this.userform.patchValue({ 
    //     emailid: this.user_data.emailid,
    //       firstname:this.user_data.firstname,
    //       lastname:this.user_data.lastname,
    //       WAT_Role:this.user_data.WFM_Role,
    //       QRT_Role:this.user_data.QRT_Role,
    //       //QRT_Roleid:this.user_data.emailid,
    //       TQT_Role:this.user_data.QFT_Role,
    //       //TQT_Roleid:this.user_data.emailid,
    //       PDQ_Role:this.user_data.PDQ_Role,
    //       //PDQ_Roleid:this.user_data.emailid,
    //       MIDashboard_Role:this.user_data.MIDashboard_Role,
    //       LAnames:this.user_data.LAnames,
    //       //:false,
    //       //MIDashboard_Roleid:this.user_data.emailid
          
    //   });
    // }
  }
  else{
   
  }
 
  }  
  getUserRoles():void{
    this.service.getAllClampUsersList()
     .subscribe(       
      data=>{     
       console.log('data of Userroles',data);       
       this.role_list=data[1];  
       this.role_list.forEach(element => {
         if(element.Role.includes('PDQ'))
         {
           this.pdq_roles.push(element.Role.toString())
          //  this.pdq_roles.push({Role:element.Role,
          //   RoleId:element.RoleId}
          //   );
         }
         else if(element.Role.includes('TQT'))
         {
          this.tqt_roles.push(element.Role.toString())
          //  this.tqt_roles.push({Role:element.Role,
          //   RoleId:element.RoleId});
         }
         else if(element.Role.includes('WAT'))
         {
          this.wat_roles.push(element.Role.toString())
          //  this.wat_roles.push({Role:element.Role,
          //   RoleId:element.RoleId});
         }
         else if(element.Role.includes('QRT') || element.Role.includes('Query'))
         {
          this.qrt_roles.push(element.Role.toString())
          //  this.qrt_roles.push({Role:element.Role,
          //   RoleId:element.RoleId});
         }
         else if(element.Role.includes('MI'))
         {
          this.midash_roles.push(element.Role.toString())
          //  this.midash_roles.push({Role:element.Role,
          //   RoleId:element.RoleId});
         }
         else if(element.Role.includes('AGS'))
         {
          this.ags_roles.push(element.Role.toString());          
         }
         else if(element.Role.includes('Compass'))
         {
          this.com_roles.push(element.Role.toString());          
         }
         else if(element.Role.includes('FME'))
         {
          this.fme_roles.push(element.Role.toString());          
         }
       });
      });  
  }

  public onFilterChange(item: any) {
    console.log(item);
  }
  public onDropDownClose(item: any) {
    console.log(item);
  }

  public onItemSelect(item: any) {
    console.log("select");
    //this.selectedItemsObj.push(item);
    //console.log(this.selectedItemsObj);
    // this.userform.patchValue({ 
    //   LAnames: this.selectedItemsObj});
  }
  public onDeSelect(item: any) {
    console.log("Deselect");
    // for (let index = 0; index < this.selectedItemsObj.length; index++) {
    //   const element = this.selectedItemsObj[index];
    //   if(element.localauthoritydesc==item.localauthoritydesc)
    //   {
    //     this.selectedItemsObj.splice(index, 1)
    //   }
      
    // }
    // const index = this.selectedItemsObj.indexOf(item.localauthoritydesc);
    // if (index > -1) {
    //   this.selectedItems.splice(index, 1); // 2nd parameter means remove one item only
    // }
    // console.log(this.selectedItemsObj);
  }

  public onSelectAll(items: any) {
    console.log("select all");
    // console.log(items);
    // for (let index = 0; index < this.dataLA.length; index++) {
    //   let element = this.dataLA[index];
    //   console.log(element);
    // }    
  }
  public onDeSelectAll(items: any) {
    console.log("unselect all");
    // this.selectedItems=[];
    // console.log(this.selectedItems);
  }

  AddandInviteUserwithEmail():void{
    this.isEmailtobeSent=true;
    localStorage.setItem('isEmailtobeSent','true');    
    this.Add_UpdateUser();
  }
  Add_UpdateUser():void
    { 
      
      let isvalid=this.isValidUser();
      if(isvalid)
      {
      this.service.getLAnamesbyUsermeailid(this.user_data.emailid)
      .subscribe(
      data=>{
        //this.options=data;
        //alert(data[1][0].Status);
        console.log('user exist or not: ', data[1]);
        console.log(this.isExistuser);
        if(!this.isExistuser && data[1][0].Status=='Already exist')
        { 
          this.openSnackBar("Entered emailid already exist!!", "OK");        
        }
        else{            
          console.log("saving the user data: ",this.user_data);
          localStorage.setItem('emailid',this.user_data.emailid);
          localStorage.setItem('firstname',this.user_data.firstname);
          localStorage.setItem('lastname',this.user_data.lastname);
          if(this.user_data.QRT_Role!=null)
          {
            this.user_data.app_names='@QRT';        
            this.user_data.app_links='@'+environment.QRTUrl; 
          }
          if(this.user_data.QFT_Role!=null)
          {
            this.user_data.app_names='@TQT'; 
            this.user_data.app_links='@'+environment.QFTUrl;        
          }
          if(this.user_data.WAT_Role!=null)
          {
            this.user_data.app_names='@WAT';        
            this.user_data.app_links='@'+environment.WebUrl; 
          }
          if(this.user_data.PDQ_Role!=null)
          {
            this.user_data.app_names='@PDQ';   
            this.user_data.app_links='@'+environment.PDQUrl;      
          }
          if(this.user_data.MIDashboard_Role!=null)
          {
            this.user_data.app_names='@MI Dashboard';        
            this.user_data.app_links='@'+environment.MIDUrl; 
          }
          console.log("saving the user form: ",this.userform);
          try
                {
                  
                  //this.http.post(`${baseUrl}/new_updateClampUser`,this.user_data).subscribe(res => {          
                  //  this.http.post(`http://localhost:3001/api/new_updateClampUser`,this.user_data).subscribe(res => {          
                  if(!this.isExistuser)
                  {
                    this.Invite_AddUserToGroupAD();               
                    //this.http_Invite_AddUserToGroupAD();
                  }
                  else{
                   this.http.post(`${baseUrl}/new_updateClampUser`,this.user_data).subscribe(res => {  
                    // this.http.post(`http://localhost:3001/api/new_updateClampUser`,this.user_data).subscribe(res => {          
                        console.log("new_updateClampUser post")
                        console.log(localStorage.getItem('Status')); 
                        
                          this.openSnackBar("User details are updated Successfully","OK");
                          this.reset();        
                      },
                      (error:any)=> {
                        //alert("Unable to raise query!: "+error); 
                        this.openSnackBar("Unable to update the user details. Please try agian!!","OK");  
                        return null});  
                  }        
                }
          catch(e)
                {            
                  this.openSnackBar("Unable to update the user details. Please try agian!!","OK");                                 
                }
                //this.Invite_AddUserToGroupAD(localStorage.getItem('accesstoken'));
                this.dialogRef.close();
        }       
      }); 
      }
                
  }
  Invite_AddUserToGroupAD():void{
    if(this.isExistuser)
    {
      return;
    }
    else
      console.log("isexistuser: "+this.isExistuser);
    let arg=''+localStorage.getItem('accesstoken');
    localStorage.setItem('Status','');
    //alert(this.userform.get('firstname'))
    console.log("saving the user data: ",this.userform);
    console.log("Invite method");
    //console.log(arg);
    let graphurl=graphAPIUrl+'/invitations';        
    let string1='Bearer '+ arg;
    //console.log("Authorization:" +string1);
    var xhr = new XMLHttpRequest();
    xhr.open("POST", graphurl, true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('Authorization',string1 );
    
    xhr.onload = function () {
    var data = JSON.parse(this.responseText);  
      console.log("Invitation mail")  
      console.log(data); 
      if(xhr.status==201)
      {
        var s=localStorage.getItem('Status');
        localStorage.setItem('Status',s+'@Invitation Sent');
        console.log(data.invitedUser.id+"----"+data.inviteRedeemUrl);
        localStorage.setItem('inviteRedeemURL',data.inviteRedeemUrl);        
        localStorage.setItem('AddedUserId',data.invitedUser.id);
        //this.user_data.inviteRedeemURL=''+localStorage.getItem('inviteRedeemURL');
        sendEmailfromXHR();  
      }
      else
      {
        if(data.error.message=='This user cannot be invited because the domain of the user’s email address is a verified domain of this directory.')
        {
          GetUserID();
          var s=localStorage.getItem('Status');
          localStorage.setItem('Status',s+'@Internal_User');
          console.log(localStorage.getItem('Status'));
        }
        else
        {
          var s=localStorage.getItem('Status');
          localStorage.setItem('Status',s+'@Error_in_Invite');
          console.log(localStorage.getItem('Status'));          
        }
        
      } 
    };
    xhr.onerror = function () {
    console.log("error");
    };
   
    xhr.send(JSON.stringify({
    "invitedUserDisplayName": this.userform.value.firstname+" "+this.userform.value.lastname,
    "invitedUserEmailAddress": this.userform.value.emailid,
    "inviteRedirectUrl": environment.QRTUrl,
    //"sendInvitationMessage": true
    "sendInvitationMessage": false
    }));
    
    this.http.post(`${baseUrl}/new_updateClampUser`,this.user_data).subscribe(res => {  
      console.log("new_updateClampUser post")
      var s= localStorage.getItem('Status');
      localStorage.setItem('Status',s+'@ClampList_Success');
      this._snackBar.open("User details are updating....", "",{"duration": 10999,panelClass: ['red-snackbar']}  ); 
      
      //this.openSnackBar("User details are created successfully!","OK");
            //this.reset();              
        },
        (error:any)=> {
          var s= localStorage.getItem('Status');
          localStorage.setItem('Status',s+'@ClampList_Error');
          //alert("Unable to raise query!: "+error); 
          this.openSnackBar("Unable to update the user details. Please try agian!!","OK");  
          return null});             
    
  }
  
  http_Invite_AddUserToGroupAD():void{
    if(this.isExistuser)
    {
      return;
    }
    else
    {
      console.log("isexistuser: "+this.isExistuser); 
      let arg=''+localStorage.getItem('accesstoken');
      localStorage.setItem('Status','');
      //alert(this.userform.get('firstname'))
      console.log("saving the user data: ",this.userform);
      console.log("starting Invitation method");
      //console.log(arg);
      let graphurl=graphAPIUrl+'/invitations';        
      let string1='Bearer '+ arg;
      var invite_input=JSON.stringify({
        "invitedUserDisplayName": this.userform.value.firstname+" "+this.userform.value.lastname,
        "invitedUserEmailAddress": this.userform.value.emailid,
        "inviteRedirectUrl": environment.QRTUrl,
        //"sendInvitationMessage": true
        "sendInvitationMessage": false
        });
        const httpOptions_Invite = {
          headers: new HttpHeaders({              
            'Content-Type': 'application/json',
            'Authorization': string1  
          })
        };
      this.http.post(graphurl,invite_input,httpOptions_Invite)
      .subscribe((data:any) => { 
        console.log(data); 
        console.log("Success in Invite call") ;
        var s=localStorage.getItem('Status');
          localStorage.setItem('Status','Invitation Sent');
          console.log(data.invitedUser.id+"----"+data.inviteRedeemUrl);
          localStorage.setItem('inviteRedeemURL',data.inviteRedeemUrl);        
          localStorage.setItem('AddedUserId',data.invitedUser.id); 
          this.openSnackBar("Invitation Sent","OK");  
          //this.http_sendEmailToUser();          
      },
      (error:any)=>{
        this.openSnackBar("Unable to Invite the user. Please try agian!!","OK");  
        return null;
      });      
    }
  }
  http_sendEmailToUser():void{
    this.service.sendEmailtoNewUser(this.user_data).subscribe(        
      data=>{
        console.log(data); 
            console.log("sent email httppost");  
            this.openSnackBar("Email Sent","OK");              
            this.reset(); 
      },
      (error:any)=>{
        this.openSnackBar("Unable to Sent Email. Please try agian!!","OK");  
        return null;
      });     
  }
  AddedUserToGroup()
  {
    let token=localStorage.getItem('accesstoken');
    //this.AddInvitedusertoGroup(''+localStorage.getItem('invitedUsermail')?.toString(),''+token);
  }
 
 
  isValidUser() {
    // alert(this.userform.value.isInternalUser);
    let isvalid=false;
    this.user_data.emailid=this.userform.value.emailid;
    this.user_data.firstname=this.userform.value.firstname;
    this.user_data.lastname=this.userform.value.lastname;
    this.user_data.QRT_Role=this.userform.value.QRT_Role;
    this.user_data.QFT_Role=this.userform.value.TQT_Role;
    this.user_data.WFM_Role=this.userform.value.WAT_Role;
    this.user_data.PDQ_Role=this.userform.value.PDQ_Role;
    this.user_data.MIDashboard_Role=this.userform.value.MIDashboard_Role;
    this.user_data.AGS_Role=this.userform.value.AGS_Role;
    this.user_data.FME_Role=this.userform.value.FME_Role;
    this.user_data.COM_Role=this.userform.value.COM_Role;
    this.user_data.LAnames=this.userform.value.LAnames;
    this.user_data.isInternalUser=this.checkInternalUser;
    this.user_data.isEnabled=1;   
    //alert(this.user_data.QRT_Role);
    if(this.user_data.isInternalUser==undefined || this.user_data.isInternalUser=='')
    {
      this.openSnackBar("Select a user as Internal/External", "OK");
      isvalid= false;
      return isvalid;
    }
    else if(!this.isInternalUser && (this.user_data.LAnames==undefined || this.user_data.LAnames.length<=0))
    {
      this.openSnackBar("Atleast Select a Local Authority", "OK");
      isvalid= false;
      return isvalid;
    }     
    else if(!this.user_data.firstname ||  this.user_data.firstname=='')
    {
      this.openSnackBar("Enter First name", "OK");
      isvalid= false;
      return isvalid;
    }
    else if(!this.user_data.lastname ||  this.user_data.lastname=='')
    {
      this.openSnackBar("Enter Last name", "OK");
      isvalid= false;
      return isvalid;
    }
    else if(!this.user_data.emailid ||  this.user_data.emailid=='')
    {
      this.openSnackBar("Enter emailid", "OK");
      isvalid= false;
      return isvalid;
    }
    else if(this.user_data.emailid!='' && !(this.user_data.emailid.toString().includes('@') && this.user_data.emailid.toString().includes('.')))
    {
      this.openSnackBar("Enter valid emailid", "OK");
      isvalid= false;
      return isvalid;
    }
    else if(!((this.user_data.QRT_Role && this.user_data.QRT_Role!='0') || (this.user_data.QFT_Role && this.user_data.QFT_Role!='0')|| (this.user_data.WFM_Role && this.user_data.WFM_Role!='0') 
    || (this.user_data.PDQ_Role && this.user_data.PDQ_Role !='0')|| (this.user_data.MIDashboard_Role && this.user_data.MIDashboard_Role!='0') 
    || (this.user_data.FME_Role && this.user_data.FME_Role !='0') || (this.user_data.AGS_Role && this.user_data.AGS_Role !='0')|| (this.user_data.COM_Role && this.user_data.COM_Role!='0')))
    {
        this.openSnackBar("Atleast assign a role for any project/tool", "OK");
        console.log("check atleast one");
        isvalid= false;
        return isvalid;
    } 
    else
    {
      return true;
    }  
   
    //alert("check already exist");          
    //isvalid= isvalid;
           
  }
  
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action,{"duration": 2000,panelClass: ['red-snackbar']}  ); 
  }
  //Resetting the controls
  reset(): void {
    this.userform.reset();
    this.InitializeForm();
    this.dialogRef.close();
  }
  
  openConfirmDialog(): void {    
    // const dialogRef = this.matDialog.open(ConfirmationdialogComponent, {
    //   width: '400px',height:'200px',
    //   backdropClass: 'custom-dialog-backdrop-class',
    //   panelClass: 'custom-dialog-panel-class',
    //   //data: { pageValue_id: this.selected_qid, pageValue_response:this.query_response}
    //   data: {
    //   }
    // });    
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed'); 
    // });
  }

}
function GetUserID()
{
  let argtoken=localStorage.getItem('accesstoken');
  console.log("GetUserID"+localStorage.getItem('AddedUserId')+"======"+localStorage.getItem('emailid'))
  let graphurl=graphAPIUrl+'/users/'+localStorage.getItem('emailid');        
  let string1='Bearer '+ argtoken;
  console.log(string1);
  var xhr = new XMLHttpRequest();
  xhr.open("GET", graphurl, true);    
  xhr.setRequestHeader('Authorization',string1 );
  xhr.setRequestHeader('Content-Type', 'application/json');
  //xhr.setRequestHeader('Content-length','30' );
  
  xhr.onload = function () {
  var data = JSON.parse(this.responseText);    
    console.log(data); 
    //alert(data.id);
    if(xhr.status==200)
    {
      localStorage.setItem('AddedUserId',data.id);
      sendEmailfromXHR(); 
    }
    console.log(localStorage.getItem('AddedUserId'));    
  };
  xhr.onerror = function () {
  console.log("error");
  };
  xhr.send();

}
function xhrRequest_Group(){
  var xhr1 = new XMLHttpRequest();
      let graphurl1=graphAPIUrl+'/groups/'+ADGroup_OID+'/members/$ref'; 
      //console.log("accesstoken_Group: "+localStorage.getItem('accesstoken_Group'))
      xhr1.open("POST", graphurl1, true);    
      xhr1.setRequestHeader('Authorization',"Bearer "+localStorage.getItem('accesstoken'));
      xhr1.setRequestHeader('Content-Type', 'application/json');
              
      xhr1.onload = function () {
        console.log(xhr1.status);
      var data1 = this.responseText;
      console.log("Adding to group"); 
        console.log(data1);
        if(xhr1.status==204)
        {
          console.log("xhr1.status"+xhr1.status);
          var s=localStorage.getItem('Status');
          localStorage.setItem('Status',s+'@ Assigned Group'); 
          console.log(localStorage.getItem('Status')); 
                                 
        }
        else
        {
          var s=localStorage.getItem('Status');
          localStorage.setItem('Status',s+'@ Error while Assigning to Group. Please try again!!');         
        }
        //this.GetUserID(arg);    
      };
      console.log('@odata.id: https://graph.microsoft.com/v1.0/directoryObjects/'+localStorage.getItem('AddedUserId'));
      xhr1.onerror = function () {
      console.log("error");
      };
      xhr1.send(JSON.stringify({
        "@odata.id": "https://graph.microsoft.com/v1.0/directoryObjects/"+localStorage.getItem('AddedUserId')
        //"@odata.id": "https://graph.microsoft.com/v1.0/directoryObjects/d9101576-eab0-4ac0-9f42-a8ec7fa7ae34"        
      }));
}
function sendEmailfromXHR() {
  if(localStorage.getItem('isEmailtobeSent')=='true')
          {
            var user={
              inviteRedeemURL:''+localStorage.getItem('inviteRedeemURL'),
              //app_names:string;
              //app_links:string;              
              emailid: ''+localStorage.getItem('emailid'),
              firstname:''+ localStorage.getItem('firstname'),
              lastname: ''+localStorage.getItem('lastname')
              }
            console.log(user);
            
            //this.http.post(`${baseUrl}/sendEmailToInviteUser`,this.user_data).subscribe(res => {          
              var xhr1 = new XMLHttpRequest();
              let graphurl1=`${baseUrl}/sendEmailToInviteUser`; 
              //let graphurl1='http://localhost:3001/api/sendEmailToInviteUser';               
              xhr1.open("POST", graphurl1, true);    
              xhr1.setRequestHeader('Content-Type', 'application/json');
                      
              xhr1.onload = function () {
                console.log(xhr1.status);
              var data1 = this.responseText;
                console.log("sending Email"); 
                var s=localStorage.getItem('Status');
                localStorage.setItem('Status',s+'@Email Sent');
                console.log(data1);  
              };
              
              xhr1.onerror = function () {
              console.log("error");
              };
              xhr1.send(JSON.stringify(user));   
          }  
}

