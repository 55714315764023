// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //baseUrl: 'http://localhost:3000/api',
 baseUrl: 'https://llcmigration-clamp-wat-rest.cyienteurope.com/api',
 graphAPIUrl:'https://graph.microsoft.com/v1.0',
 WebUrl:'https://llcmigration-wat.cyienteurope.com',
 ADGroup_OID:'e323f89e-e6f6-4f09-b45d-cdc7fc76a795'

 //For eamilTemplate
 , QFTUrl: 'https://llcmigration-tqt.cyienteurope.com'  
 , QRTUrl: 'https://llcmigration-qrt.cyienteurope.com' 
 , PDQUrl: 'https://llcmigration-clamp-pdq.cyienteurope.com'
 , MIDUrl: 'https://llcmigration-mi-dashboard.cyienteurope.com'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
