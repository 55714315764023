import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms'
import{AppServiceService} from '../app-service.service';

interface LocalAuthorities {
  id: number;
  value: string;
}

@Component({
  selector: 'app-create-job',
  templateUrl: './create-job.component.html',
  styleUrls: ['./create-job.component.scss']
})
export class CreateJobComponent {
  localAuthorities: LocalAuthorities[] = [
    { id: 0, value: 'LA1' },
    { id: 1, value: 'LA2' },
    { id: 2, value: 'LA3' }
  ];
  newJobForm = new FormGroup({

    jobId: new FormControl(null, [Validators.required]),
    jobDesc: new FormControl(null, [Validators.required]),
    lacode: new FormControl(null, [Validators.required]),
  });

  constructor(private service:AppServiceService) {
    
  }

  // get Local Authorities

//   getLAFromAPI(){    
//     this.service.getData()
//     .subscribe(
//      data=>{
//        //this.dataSource=data;
//        this.dataSource=new MatTableDataSource(data);
//        this.dataSource.paginator = this.paginator;
//      this.dataSource.sort = this.sort;
//      });
     
  
//  }

  onSubmit() {
   // console.log(this.newJobForm.value);// POst this to DB
   this.postJob(this.newJobForm.value);
  }
postJob(param:any)
{
  this.service.postJob(param)
  .subscribe(
   data=>{
    console.log(data);
     //this.dataSource=data;
   });
}

}
