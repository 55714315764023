<div> 
    <div> 
     <div class="headertoolbar">  
       <mat-nav-list style="display: flex;flex-direction: row;align-items: center;justify-content: center;">  
       <mat-list-item> 
         <mat-form-field >
           <mat-label>Select User</mat-label>
           <mat-select [(ngModel)]="userid" (ngModelChange)='onUserSelected($event)'>
             <mat-option [value]="0" >Select All</mat-option>
                 <mat-option *ngFor="let user of users"   [value]="user.emailid">
               {{user.emailid}}
             </mat-option>
           </mat-select>
         </mat-form-field>
          </mat-list-item>
             <mat-list-item>
               <button mat-raised-button (click)="assignjob()">Assign Job</button>
               <!-- <button mat-raised-button (click)="callsandhya()">callsandhya</button> -->
             </mat-list-item>
            
             <mat-list-item>               
                 <button #file mat-flat-button color="primary">Bulk Job Assign
                     <input multiple (change)="onSelectExcel($event)"  style="opacity: 0; position:absolute; left:0px; top:0px; width:100%; height:100%;" type="file"/>
                 </button>               
               <!-- <button mat-raised-button ><input type="file" (change)="onSelectExcel($event)" multiple="false" /></button> -->
             </mat-list-item>
           
             <mat-list-item> 
               <button mat-raised-button (click)="importExcel()">Export to Excel</button>
               <!-- <mat-icon title="Import In Excel" (click)="importExcel()"> import_export</mat-icon> -->
             </mat-list-item>
             <mat-list-item> 
               <button mat-raised-button (click)="Refresh()">Refresh</button>                
             </mat-list-item>
        </mat-nav-list>
   </div>
   <div>
     <!-- <h3 style=float:right;padding-right:200px;>{{latext}}</h3> -->
      <mat-tab-group (selectedIndexChange)="_setDataSource($event)"  dynamicHeight=5%>
      
         <mat-tab>
           <ng-template mat-tab-label>
             <mat-icon class="example-tab-icon">list_alt</mat-icon>
             Jobs
           </ng-template>     
           <div class="grid-wrapper">         
           <ag-grid-angular         
             class="ag-theme-alpine"        
             #agGrid1
             style="width:100%;height:100%;"
             id="myGrid"
             class="ag-theme-alpine"
             [columnDefs]="columnDefs"
             [defaultColDef]="defaultColDef"
             [rowData]="rowData"
              [pagination]="true"          
             (gridReady)="onGridReady($event)"
             [overlayLoadingTemplate]="loadingTemplate"
             [overlayNoRowsTemplate]="noRowsTemplate"
             rowSelection="multiple">
           </ag-grid-angular>  </div>    
         </mat-tab>       
       
       </mat-tab-group>
   </div>
   </div>
     
     <!-- table Row -->
    
