import { Component, OnInit,AfterViewInit, ViewChild,ElementRef, Inject, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppServiceService } from './app-service.service';
import {SelectionModel} from '@angular/cdk/collections';
import {MatSidenav} from '@angular/material/sidenav';

import{BreakpointObserver} from "@angular/cdk/layout"
import {MatTableDataSource} from '@angular/material/table';
import { SideNavDrawerComponent } from './side-nav-drawer/side-nav-drawer.component';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
//import { AuthenticationResult, EventMessage, EventType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest,EventMessage, EventType,} from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RouterModule, Routes,Router } from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import { PopupComponent } from '../app/job-schedule/popup/popup.component'
import {MatDialog, MatDialogConfig } from '@angular/material/dialog';

const baseUrl=environment.baseUrl;
interface User {
  value: string;
  viewValue: string;
}

export interface Config {
  heroesUrl: string;
  textfile: string;
  date: any;
}
 /**Mock Data */
var ELEMENT_DATA: JobElement[]=[];
export interface JobElement {
  jobId: number;
  jobAssignedTo: string;
  jobStatus: string;
 
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent  implements OnInit, AfterViewInit,OnDestroy{
  silentRequest:any;
  user_obj= {
    MailId:'',
    RoleID:0
   };
 // title = 'Angular 9 - Angular v2 Sample';
 userMailId:any;
 public static userRoleID:any;
 
  isIframe = false;
  loginDisplay = false;
  isjustloggedout:boolean=false;
  role:any;
  isADMIN:boolean=false;
  isQR:boolean;
  isQM:boolean;
  isQRP:boolean;
  title = 'angular-app';
  
  id:string="";
  useremail:string="";
  laidfirst:any="";
  laidtext:any="";
  users: User[] = [
    {value: '0', viewValue: 'User1'},
    {value: '1', viewValue: 'User2'},
    {value: '2', viewValue: 'User3'}
  ];

  displayedColumns: string[] = ['jobId', 'jobAssignedTo', 'jobStatus'];
  dataSource = new MatTableDataSource<JobElement>(ELEMENT_DATA);  
  selection = new SelectionModel<JobElement>(true, []); 

  @ViewChild('someInput') someInput!: ElementRef;
  
  @ViewChild('sidenav') mySidenav: MatSidenav;

  private readonly _destroying$ = new Subject<void>();
  constructor(private router: Router,private matDialog: MatDialog,private _snackBar: MatSnackBar,private http:HttpClient,private service:AppServiceService,private observer: BreakpointObserver,@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  private authService: MsalService,
  private msalBroadcastService: MsalBroadcastService){    
  }
  
  ngOnInit():void{ 
    this.isIframe = window !== window.parent && !window.opener;
    this.authService.handleRedirectObservable();
    this.msalBroadcastService.msalSubject$
      .pipe(
        //filter((status: InteractionStatus) => status === InteractionStatus.None),
         filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),         
        takeUntil(this._destroying$)
      )
      .subscribe((result) => {
        this.setLoginDisplay();
      });
     if(localStorage.getItem('logoutAction')!='true')
     {
      this.loginRedirect(); 
     }
     else
     {
       localStorage.clear();
       this.router.navigate(['logout']);
     }
      
  }
  
  ngAfterViewInit() {
  
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
 
  loginRedirect() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup)
    {
      if (this.msalGuardConfig.authRequest)
        {
        this.authService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
        this.authService.instance.setActiveAccount(response.account);
        });
        } else {
          this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
          });
          }
      } else {
        if (this.msalGuardConfig.authRequest){
        this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
      }
    }
  
  
  loginRedirect1() {
    console.log("loginRedirect");
    this.authService.loginRedirect()
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
      //alert('alert if--else in module.ts');
    } else {
      this.authService.loginRedirect();
      //alert('alert if--else in module.ts');
    }
    
  }
  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  //  alert("set: "+this.loginDisplay);
  // alert(this.authService.instance.getAllAccounts().length);
    if(this.authService.instance.getAllAccounts().length>0)
    {
      let accountinfo=this.authService.instance.getAllAccounts();
      console.log(accountinfo[0].username);
      this.userMailId=accountinfo[0].username;
      this.user_obj.MailId=this.userMailId;
      if(accountinfo[0].username!='')
      {
        sessionStorage.setItem('useremailid',accountinfo[0].username);
        this.getAccessToken(accountinfo[0]);
        this.getAccessToken_Group(accountinfo[0])
      //  alert(accountinfo[0].username);
      }
      this.getUserRoleID();
      //alert("accountinfo");
    }
  }
  getAccessToken(acc_info:any)
  {
    //alert("token");
      ////////////////////////////////////////ACCUIRETOKEN/////////////////////////////////////
      let currentAccount = this.authService.instance.getAccountByUsername(acc_info.username);
      this.silentRequest = {
          scopes: ["User.Invite.All"],
          account: currentAccount,
          forceRefresh: false
      };

      let request = {
          scopes: ["User.ReadWriteAll"],
          loginHint: acc_info.username // For v1 endpoints, use upn from idToken claims
      };

      const tokenResponse = this.authService.instance.acquireTokenSilent(this.silentRequest).then((resp:any)=>{
        console.log(resp);
        console.log(resp.accessToken);
        localStorage.setItem('accesstoken',resp.accessToken);
        
      })
  }

  getAccessToken_Group(acc_info:any)
  {
    //alert("token");
      ////////////////////////////////////////ACCUIRETOKEN/////////////////////////////////////
      let currentAccount = this.authService.instance.getAccountByUsername(acc_info.username);
      this.silentRequest = {
          scopes: ['user.read','User.ReadWrite.All',],
          account: currentAccount,
          forceRefresh: false
      };

      // let request = {
      //     scopes: ["User.ReadWriteAll"],
      //     loginHint: acc_info.username // For v1 endpoints, use upn from idToken claims
      // };

      const tokenResponse = this.authService.instance.acquireTokenSilent(this.silentRequest).then((resp:any)=>{
        console.log(resp);
        console.log(resp.accessToken);
        localStorage.setItem('accesstoken_Group',resp.accessToken);
        
      })
  }
  
  menudisplay()
  {
  //  this.someInput.nativeElement.value =  localStorage.getItem("latext"); 
    this.laidfirst = localStorage.getItem("laid"); 
   // alert(this.laidfirst);
   // this.sidenav.toggle();
   // this.laidfirst = localStorage.getItem("laid"); 
    if( this.laidfirst != null && this.laidfirst != '')
    {
     // alert(this.laidfirst);
      this.mySidenav.toggle();
    }
    else{
     // this.sidenav.toggle();
    }

  }

  homeClick()
  {

    
    this.mySidenav.toggle();
    localStorage.clear();

  }
  getDataFromAPI(){    
    var res=this.service.getData(this.id,this.useremail).subscribe((response) =>{
      return JSON.parse(response.toString());
    },(error)=>{
      console.log('Error: ', error);  
    });
    return res;
  }
 
   /** TODO Implement Row selection */
   /** Whether the number of selected elements matches the total number of rows. */
   isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: JobElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.jobId + 1}`;
  }

 

  getUserRoleID(){   
    this.service.getRoleId(this.user_obj.MailId)
    .subscribe(      
     data=>{     
      AppComponent.userRoleID=data[0].WFM_RoleID;   
      //console.log(AppComponent.userRoleID);
      this.showUserPage();  
     });    

    
  }

  showUserPage():void
  {
    //alert(this.isADMIN);
    console.log( "AppComponent.userRoleID:"+AppComponent.userRoleID);
    if(!AppComponent.userRoleID || AppComponent.userRoleID.toString()!='7')
    {
      sessionStorage.setItem('watRole',  AppComponent.userRoleID);
      this.router.navigate(['home']);      
      //alert("===7==="+this.isADMIN);
    }
    else if(AppComponent.userRoleID.toString()=='7')
    {
      sessionStorage.setItem('watRole',  AppComponent.userRoleID);
      this.router.navigate(['home']);   
      this.isADMIN=true;
      //alert("===7==="+this.isADMIN);
    }     
    else
    {
      sessionStorage.setItem('watRole',  AppComponent.userRoleID);
      this.openDialog('Close', {});              
    }   
    //alert(sessionStorage.getItem('watRole'));
  }

  SAMLLogout()
  {
    
    localStorage.setItem('logoutAction', 'true');   
    this.authService.logoutRedirect();
  }

  openDialog(action:any,obj:any) {
    var w1:string;
    var h1:string;
    if (action==='Querybuilder')
   {
     w1='900px';
     h1='600px';
   }
   else
   {
     w1='400px';
     h1='200px';
   }
   obj.action = action;  
   const dialogRef = this.matDialog.open(PopupComponent, {
     width: w1,
     height:h1,
     data:obj
   });
  
   dialogRef.afterClosed().subscribe(result => {
     if(result.event == 'Add'){
      // this.getDataFromAPI(this.la,this.useremail);
      // this.addRowData(result.data);
     }    
   });
 }
 

 

}
