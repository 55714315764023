<!-- Header -->
<!-- <mat-toolbar class="toolbar">
  <img src="../assets/Cyient-logo.png">
</mat-toolbar> -->

<mat-toolbar class="toolbar1" >   
  <!-- <button mat-button  (click)="test()" ><mat-icon>menu</mat-icon></button>  -->
    <!-- <button mat-button  (click)="laidfirst != 'undefined' ? sidenav.open():sidenav.toggle" ><mat-icon>menu</mat-icon></button>  -->
    <!-- <button mat-button  (click)="sidenav.toggle()" ><mat-icon>menu</mat-icon></button>  -->
    <button mat-button  (click)="menudisplay()"><mat-icon>menu</mat-icon></button> 
    <span>Work Allocation Tool</span> 
    <div style="right:15px;position:absolute;"> <img src="../assets/cyientlogosmall.png"></div>
</mat-toolbar>
<!-- <input #someInput placeholder="Your favorite sea creature"> -->
<!-- Header Ends -->

<!-- Side Nav -->
<mat-sidenav-container>
  
  <mat-sidenav mode="side" #sidenav opened="false" style="background-color:#0099a8;color:white">    
    <mat-nav-list  >
        <button mat-menu-item routerLink="home" (click)="homeClick()" >
            <mat-icon>home</mat-icon>
            <span>Home</span>
          </button> 
      <button mat-menu-item routerLink="job">
        <mat-icon>list</mat-icon>
        <span>Jobs</span>
      </button>  
      <button mat-menu-item routerLink="tqtjobs">
        <mat-icon>list</mat-icon>
        <span>TQTJobs</span>
      </button>    

      <!-- <mat-divider></mat-divider>
      <button mat-menu-item routerLink="user">       
        <span><b>User Forms</b></span>        
      </button>
      <button mat-menu-item routerLink="job">
        <mat-icon>input</mat-icon>
        <span>User List</span>
      </button> 
     
      <button mat-menu-item  routerLink="dashboard">
        <mat-icon>input</mat-icon>
        <span>Dashboard</span>
      </button>-->
      <button mat-menu-item routerLink="reports">
        <mat-icon>table_chart</mat-icon>
        <span>Reports</span>
      </button>
      <button mat-menu-item routerLink="templates">
        <mat-icon>input</mat-icon>
        <!-- <span><i class="fa-solid fa-file-spreadsheet"></i></span>
        <span><i class="fa-solid fa-file-arrow-down"></i></span>
        <span><i class="fa-solid fa-files"></i></span> -->
        <span>Templates</span>
      </button>
      
      
      <button *ngIf="isADMIN" mat-menu-item routerLink="assignroles">
        <!-- <mat-icon>adminpanel</mat-icon> -->
        <mat-icon>supervisor_account</mat-icon>
        <span>Assign Roles</span>
      </button>
      
      <!-- <button mat-menu-item routerLink="qrtlareport">
                <mat-icon>table_chart</mat-icon>
        <span>QRT Report</span>
      </button> -->
      <button mat-menu-item (click)="SAMLLogout()">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </button>   
      
    </mat-nav-list>
    <mat-menu #menu="matMenu"  style="background-color:#0099a8;">
      <button mat-menu-item>
        <mat-icon  style="color:#0099a8;">dialpad</mat-icon>
        <span>Redial</span>
      </button>
      <button mat-menu-item disabled>
        <mat-icon>voicemail</mat-icon>
        <span>Check voice mail</span>
      </button>
      <button mat-menu-item>
        <mat-icon>notifications_off</mat-icon>
        <span>Disable alerts</span>
      </button>
    </mat-menu>
  </mat-sidenav>
<!-- Side Nav ends -->

  <!--Content Body -->
  <mat-sidenav-content> 
   <router-outlet></router-outlet>       
    </mat-sidenav-content>

  <!--Content Body Ends -->
</mat-sidenav-container>