import {AfterViewInit, ViewChild, Component, OnInit,ElementRef} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import{AppServiceService} from '../app-service.service';
import{jobList} from '../job-schedule/JobScheduleComponent.Model';
import{BreakpointObserver} from "@angular/cdk/layout";
import * as XLSX from 'xlsx';//Import Excel
import {MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PopupComponent } from '../job-schedule/popup/popup.component';
import { TestBed } from '@angular/core/testing';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { HttpClient,HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { QueryBuilderConfig } from 'angular2-query-builder';
import { ColDef, GridOptionsWrapper, _ } from 'ag-grid-community';
import { AgGroupComponent } from 'ag-grid-community';
import {FormGroup, FormControl} from '@angular/forms';
import * as agCharts from 'ag-charts-community';
import { DatePipe } from '@angular/common';
import { MatSelectChange } from "@angular/material/select";
import { ExampleHeaderComponentComponent } from './example-header-component/example-header-component.component';


const baseUrl=environment.baseUrl;

export interface JobElement {
  jobid: string;
  jobdesc:string;
  assignedto: string;
  jobstatus: number;
  localauthoritydesc: string;
  status:number; 
}
interface User {
  emailid: string;
  //viewValue: string;
}
interface LocalAuthorities {
  localauthoritydesc: string;
  localauthoritycode: string;
}
interface Reporttype {
  value: string;
  viewValue: string;
}
interface Reportdata {
  quarter: string;  
  iphone: string;  
}
 /**Mock Data */
 const ELEMENT_DATA:jobList[]=[];
 
@Component({
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent {
  ExampleHeaderComponentComponent = ExampleHeaderComponentComponent;
  liverptdate:[];
  rptText:any;
  latext:any;
  lavalue:string;
  campaignOne: FormGroup;
  campaignTwo: FormGroup;

  minRowHeight:any;
  gridApi:any;
  gridColumnApi:any;

  gridApiQC:any;
  gridColumnApiQC:any;

  gridApiQA:any;
  gridColumnApiQA:any;

  columnDefs:any;
  colDefs:any;
  columnDefstest:any;
  columnDefsQC:any;
  columnDefsQA:any;
  defaultColDef:any;
  rowData: [];
  rowDatatest: [];
  rowDataQC: [];
  rowDataQA: [];
  autoGroupColumnDef:any;
  noRowsTemplate:any;
 loadingTemplate:any;

 //QRT LA Summary & Outstanding
 columnDefs_Sum:any;
 defaultColDef_Sum:any;
 rowDataSummary: []; 
 columnDefs_Out:any;
 defaultColDef_Out:any;
 rowDataOutstanding: []; 
 isChartExist:boolean=true;

//All Reports Desciption added here
  reporttype: Reporttype[] = [
    {value: 'JS', viewValue: 'Digitiser Audit Report'},
    {value: 'QCJS', viewValue: 'QC Jobs Summary'},
    {value: 'QAJS', viewValue: 'QA Jobs Summary'},
    {value: 'LA', viewValue: 'Route Volume Report'},
    {value: 'SS', viewValue: 'Status Summary Report'},
    {value: 'CM', viewValue: 'Capture Method Report'},
    {value: 'DA', viewValue: 'Digitiser QC Report'},  
    {value: 'QRT_Sum', viewValue: 'QRT Charges Summary Report'},
    {value: 'QRT_Out', viewValue: 'QRT Charges Outstanding Report'},
    {value: 'DLN', viewValue: 'Digitiser Live Report'} ,
    {value: 'QC_L', viewValue: 'QC Live Report'} ,
    {value: 'QAL', viewValue: 'QA Live Report'}  
  ];
  //jobtypeselected:string;
  reporttypeselected='JS';
  loaderImage:Boolean=false;
  users: User[] = [];
  
  localAuthorities: LocalAuthorities[] = [];
 public  rptdata:Reportdata[]=[];
  selectedla="";
  id:string="0";
  useremail:string="0";
  la:string="";
  tabid:string="";
  masterSelected:boolean;
  eGridDiv:any;

  
  selection = new SelectionModel<jobList>(true, []);  
  searchValue="";
   dataSource:any;
   dataSourceqc:any;
   dataSourceqa:any;
  userid:string;

  @ViewChild('agGrid1', { static: true }) agGrid1: AgGroupComponent;
  @ViewChild('agGrid2', { static: true }) agGrid2: AgGroupComponent;
 
  public options: any;
  schema:any;
  public isButtonVisible = true;

   curr:any = new Date; // get current date
   first:any = this.curr.getDate() - this.curr.getDay(); // First day is the day of the month - the day of the week
   last:any = this.first + 6; // last day is the first day + 6
  
   firstday:any = new Date(this.curr.setDate(this.first)).toUTCString();
   lastday:any = new Date(this.curr.setDate(this.last)).toUTCString();

  range = new FormGroup({
    start: new FormControl(new Date().toISOString()),
    end: new FormControl(new Date().toISOString())
  }); 

  
  fromdate:any;
  todate:any;
  myDate:any;
  show:boolean=true;
  constructor(private datePipe: DatePipe,private service:AppServiceService,private matDialog: MatDialog,private observer: BreakpointObserver,private http : HttpClient,private route: ActivatedRoute,private elementRef: ElementRef)
   {
     // To get current week first andlast date      
    var curr = new Date; // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6
    
    var firstday = new Date(curr.setDate(first)).toUTCString();
    var lastday = new Date(curr.setDate(last)).toUTCString();


  //  this.range.controls.first.setValue(firstday);
    //this.range.controls.end.setValue(lastday);
 //alert(firstday);


   // this.range.value.start=new Date().toISOString()
   // this.range.value.end=new Date().toISOString()
   // this.start.setvalue=firstday
    //this.end.setvalue=lastday
    // end here

      this.options ={};
      this.latext=localStorage.getItem('latext');
      const today = new Date();
      const month = today.getMonth();
      const year = today.getFullYear();

      this.campaignOne = new FormGroup({
        start: new FormControl(new Date(year, month, 13)),
        end: new FormControl(new Date(year, month, 16)),
      });

      this.campaignTwo = new FormGroup({
        start: new FormControl(new Date(year, month, 15)),
        end: new FormControl(new Date(year, month, 19)),
      });

      this.loadingTemplate =
      '<span class="ag-overlay-loading-center">Please wait while your reports are loading</span>';
      this.noRowsTemplate =
      '<span style="padding: 10px; border: 2px solid #444; background: #0099a8;">No Jobss </span>';

      this.masterSelected = false;   
      this.colDefs=[];
      this.columnDefs = [
      ];

    var gridOptions:any = {

        defaultColDef: {
            sortable: true,
            filter: 'agTextColumnFilter',
            resizable: true
            
        },

        columnDefs: this.columnDefs,
        enableSorting: true,
        enableFilter: true,
        pagination: true
    };

    this.autoGroupColumnDef = {
      headerName: 'Group',
      minWidth: 170,
    
      field: 'select',
      valueGetter: function (params:any) {
        if (params.node.group) {
          return params.node.key;
        } else {
          return params.data[params.colDef.field];
        }
      },
      headerCheckboxSelection: true,
      cellRenderer: 'agGroupCellRenderer',
      cellRendererParams: { checkbox: true },
    };
    this.defaultColDef = {
     
      sortable: true,     
      filter: true,
      flex: 1,     
      minWidth: 100,
      resizable: true
    
    };
    
   }

  //OnInit
  ngOnInit(): void
  { 
    this.range.value.start = new FormControl(new Date().toISOString());
    this.range.value.end = new FormControl(new Date().toISOString());
    this.tabid="Report";
    this.isChartExist=true;
    //alert(this.isChartExist)
     
  }

  //AfterViewInit
 ngAfterViewInit()  {
       
 }

 //Get Data From API
 getDataFromAPI(){  

 //alert(this.fromdate);
//alert(this.todate);
//alert(this.firstday);
//alert(this.lastday);
      this.rptText='Digitiser Audit Report';
      this.fromdate=this.datePipe.transform(this.range.value.start, 'dd-MM-YYYY'); //this.range.value.start;
      this.todate=this.datePipe.transform(this.range.value.end, 'dd-MM-YYYY');
      this.service.getJobSummary(localStorage.getItem('schema'),this.fromdate,this.todate)
      .subscribe(      
        data=>{   
          console.log('jobs data',data);
          if(data==null)
          {
            this.noRowsTemplate =
            '<span style="padding: 10px; border: 2px solid #444; background: #0099a8;">No Jobs </span>';
          }
          else
          {
           // alert(data);
           localStorage.setItem('jobsummary', JSON.stringify(data));   
            this.rowData=data;
          }       
          //this.gridApi.setColumnDefs(this.dynamicallyConfigureColumnsFromObject(data[0]));  
          this.gridApi.setColumnDefs(
            [ 
              {
            headerName: this.latext + ' - ' +  this.rptText,
            suppressSizeToFit: false,
            width:150,
              children:
            this.dynamicallyConfigureColumnsFromObject1(data[0],data)
          
        }]);        
        
        });   
   
 
  }
//LA Volume Report
  getLAVolumeSummaryReport()
  {   
     
      this.service.getLAVolumeReport(localStorage.getItem('schema'))

      .subscribe(        
        data=>{   
          if(data==null)
          {
            this.noRowsTemplate =
            '<span style="padding: 10px; border: 2px solid #444; background: #0099a8;">No Jobs </span>';
          }
          else
          {
            //alert(data);
            console.log('gg',data);
            localStorage.setItem('lavolumesummary', JSON.stringify(data)); 
            this.rowData=data;
           
          }
        
          //this.gridApi.setColumnDefs(this.dynamicallyConfigureColumnsFromObject(data[0]));          
          this.gridApi.setColumnDefs(
            [ 
              {
            headerName: this.latext + ' - ' +  this.rptText,
              children:
            this.dynamicallyConfigureColumnsFromObject(data[0])
          
        }]);    
        });   
   
  }

  //Status Summary Report
  getStatusSummaryReport()
  {   
    this.fromdate=this.datePipe.transform(this.range.value.start, 'dd-MM-YYYY'); //this.range.value.start;
    this.todate=this.datePipe.transform(this.range.value.end, 'dd-MM-YYYY');
      this.service.getStatusSummaryApi(localStorage.getItem('schema'),localStorage.getItem('laid'),this.fromdate,this.todate)

      .subscribe(        
        data=>{   
          if(data==null)
          {
            this.noRowsTemplate =
            '<span style="padding: 10px; border: 2px solid #444; background: #0099a8;">No Jobs </span>';
          }
          else
          {
            //alert(data);
            //console.log('gg',data);
            localStorage.setItem('statussummary', JSON.stringify(data)); 
            this.rowData=data;
           
          }
        
         // this.gridApi.setColumnDefs(this.dynamicallyConfigureColumnsFromObject(data[0]));          
         this.gridApi.setColumnDefs(
          [ 
            {
          headerName: this.latext + ' - ' +  this.rptText,
            children:
          this.dynamicallyConfigureColumnsFromObject(data[0])
        
      }]);    
        });   
   
  }
  
   //Capture Method Report
   getCaptureMethodReport()
   {   
     
       this.service.getCaptureMethodApi(localStorage.getItem('schema'),this.fromdate,this.todate)
 
       .subscribe(        
         data=>{   `  `
           if(data==null)
           {
             this.noRowsTemplate =
             '<span style="padding: 10px; border: 2px solid #444; background: #0099a8;">No Jobs </span>';
           }
           else
           {
             //alert(data);
             //console.log('gg',data);
             localStorage.setItem('capturemethod', JSON.stringify(data)); 
             this.rowData=data;
            
           }
         
          // this.gridApi.setColumnDefs(this.dynamicallyConfigureColumnsFromObject(data[0]));          
          this.gridApi.setColumnDefs(
           [ 
             {
           headerName: this.latext + ' - ' +  this.rptText,
             children:
           this.dynamicallyConfigureColumnsFromObject(data[0])
         
       }]);    
         });   
    
   }

    //QC Job Summary Report
    getQCJobSummaryReport()
    {   
      this.fromdate=this.datePipe.transform(this.range.value.start, 'dd-MM-YYYY'); //this.range.value.start;
      this.todate=this.datePipe.transform(this.range.value.end, 'dd-MM-YYYY');
        this.service.getQCJobSummaryReport(localStorage.getItem('schema'),this.fromdate,this.todate)
  
        .subscribe(        
          data=>{   `  `
            if(data==null)
            {
              this.noRowsTemplate =
              '<span style="padding: 10px; border: 2px solid #444; background: #0099a8;">No Jobs </span>';
            }
            else
            {
              //alert(data);
              //console.log('gg',data);
              localStorage.setItem('QCJobSummary', JSON.stringify(data)); 
              this.rowData=data;
             
            }
          
           // this.gridApi.setColumnDefs(this.dynamicallyConfigureColumnsFromObject(data[0]));          
           this.gridApi.setColumnDefs(
            [ 
              {
            headerName: this.latext + ' - ' +  this.rptText,
              children:
            this.dynamicallyConfigureColumnsFromObject(data[0])
          
        }]);    
          });   
     
    }

  //QA Job Summary Report
  getQAJobSummaryReport()
  {   
    
      this.service.getQAJobSummaryReport(localStorage.getItem('schema'),this.fromdate,this.todate)

      .subscribe(        
        data=>{   `  `
          if(data.length==null)
          {
            this.noRowsTemplate =
            '<span style="padding: 10px; border: 2px solid #444; background: #0099a8;">No Jobsggg </span>';
          }
          else
          {
            //alert(data);
            //console.log('gg',data);
            localStorage.setItem('QAJobSummary', JSON.stringify(data)); 
            this.rowData=data;
           
          }
        
         // this.gridApi.setColumnDefs(this.dynamicallyConfigureColumnsFromObject(data[0]));          
         this.gridApi.setColumnDefs(
          [ 
            {
          headerName: this.latext + ' - ' +  this.rptText,
            children:
          this.dynamicallyConfigureColumnsFromObject(data[0])
        
      }]);    
        });   
   
  }

    //QC Job Summary Report
    getDigitiserAuditReport()
    {   
     // this.fromdate=this.datePipe.transform(this.range.value.start, 'MM-dd-YYYY'); //this.range.value.start;
     // this.todate=this.datePipe.transform(this.range.value.end, 'MM-dd-YYYY');
     // alert('fromdate '+this.fromdate);
     // alert('todate '+this.todate);
        this.service.getDigitiserAuditReport(localStorage.getItem('schema'),this.fromdate,this.todate)
  
        .subscribe(        
          data=>{   `  `
            if(data==null)
            {
              this.noRowsTemplate =
              '<span style="padding: 10px; border: 2px solid #444; background: #0099a8;">No Jobs </span>';
            }
            else
            {
              //alert(data);
              //console.log('gg',data);
              localStorage.setItem('DigitiserAudit', JSON.stringify(data)); 
              this.rowData=data;
             
            }
          
           // this.gridApi.setColumnDefs(this.dynamicallyConfigureColumnsFromObject(data[0]));          
           this.gridApi.setColumnDefs(
            [ 
              {
            headerName: this.latext + ' - ' +  this.rptText,
            Width: 200,
            resizable: true,
            suppressSizeToFit: false,
           
              children:
            this.dynamicallyConfigureColumnsFromObject1(data[0],data)
           
          
        }]);    
          });   
     
    }

    //Digitiser Live  Report New
     //QC Job Summary Report
     getDigitiserLiveReportNew()
     {   
       this.fromdate=this.datePipe.transform(this.range.value.start, 'dd-MM-YYYY'); //this.range.value.start;
       this.todate=this.datePipe.transform(this.range.value.end, 'dd-MM-YYYY');
      // alert('fromdate '+this.fromdate);
      // alert('todate '+this.todate);
         this.service.getDigitiserLiveReportNew(localStorage.getItem('schema'))
   
         .subscribe(        
           data=>{   `  `
             if(data==null)
             {
               this.noRowsTemplate =
               '<span style="padding: 10px; border: 2px solid #444; background: #0099a8;">No Jobs </span>';
             }
             else
             {
               //alert(data);
               //console.log('gg',data);
               localStorage.setItem('DigitiserLiveReportNew', JSON.stringify(data)); 
               this.rowData=data;
              
             }
           
            // this.gridApi.setColumnDefs(this.dynamicallyConfigureColumnsFromObject(data[0]));          
            this.gridApi.setColumnDefs(
             [ 
               {
             headerName: this.latext + ' - ' +  this.rptText,
             Width: 200,
             resizable: true,
             suppressSizeToFit: false,
            
               children:
             this.dynamicallyConfigureColumnsFromObject1(data[0],data)
            
           
         }]);    
           });   
      
     }

     
     //QC Live Report
     getQCLiveReport()
     {   
       
         this.service.getQCLiveReport(localStorage.getItem('schema'))
   
         .subscribe(        
           data=>{   `  `
             if(data==null)
             {
               this.noRowsTemplate =
               '<span style="padding: 10px; border: 2px solid #444; background: #0099a8;">No Jobs </span>';
             }
             else
             {
               //alert(data);
               //console.log('gg',data);
               localStorage.setItem('QCLiveReport', JSON.stringify(data)); 
               this.rowData=data;
              
             }
           
            // this.gridApi.setColumnDefs(this.dynamicallyConfigureColumnsFromObject(data[0]));          
            this.gridApi.setColumnDefs(
             [ 
               {
             headerName: this.latext + ' - ' +  this.rptText,
             Width: 200,
             resizable: true,
             suppressSizeToFit: false,
            
               children:
             this.dynamicallyConfigureColumnsFromObject1(data[0],data)
            
           
         }]);    
           });   
      
     }
     //QA Live Report
     getQALiveReport()
     {   
      
         this.service.getQALiveReport(localStorage.getItem('schema'))
   
         .subscribe(        
           data=>{   `  `
             if(data==null)
             {
               this.noRowsTemplate =
               '<span style="padding: 10px; border: 2px solid #444; background: #0099a8;">No Jobs </span>';
             }
             else
             {
               //alert(data);
               //console.log('gg',data);
               localStorage.setItem('QALiveReport', JSON.stringify(data)); 
               this.rowData=data;
              
             }
           
            // this.gridApi.setColumnDefs(this.dynamicallyConfigureColumnsFromObject(data[0]));          
            this.gridApi.setColumnDefs(
             [ 
               {
             headerName: this.latext + ' - ' +  this.rptText,
             Width: 200,
             resizable: true,
             suppressSizeToFit: false,
            
               children:
             this.dynamicallyConfigureColumnsFromObject1(data[0],data)
            
           
         }]);    
           });   
      
     }
  // Dynamic Column Setting
 dynamicallyConfigureColumnsFromObject(anObject:any){ 
  this.colDefs.length=0; 
  
 
  const keys = Object.keys(anObject)
 
  keys.forEach(key => 
  
    this.colDefs.push({field : key,aggFunc: "sum"}));
    
 
    return this.colDefs;
  
}
 // Dynamic Column Setting
 dynamicallyConfigureColumnsFromObject1(anObject:any,data:any){ 
  this.colDefs.length=0; 
  
 
  const keys = Object.keys(anObject)
  if(data=='E')
  {
  }
  else
  {
   
  keys.forEach(key => 
  
    this.colDefs.push({field : key,aggFunc: "sum"}));
    
 
    return this.colDefs;
  }
}
//On Grid ready
onGridReady(params:any) {  
  this.gridApi = params.api;  
  this.gridColumnApi = params.columnApi;
  
//this code to get curretn date 
  this.myDate = new Date();
  this.myDate = this.datePipe.transform(this.myDate, 'MM-dd-YYYY');
//end here 
//alert(this.myDate.getDay());//3
//alert(this.myDate.getDate());//2
//var firstday = new Date(this.myDate.setDate(this.myDate.getDate() - this.myDate.getDay())).toUTCString();
//var lastday = new Date(this.myDate.setDate(this.myDate.getDate() - this.myDate.getDay() + 6)).toUTCString();

//alert(this.firstday);
//alert(this.lastday);
//this.fromdate= this.datePipe.transform(this.firstday, 'MM-dd-YYYY');
//this.todate= this.datePipe.transform(this.lastday, 'MM-dd-YYYY');
//alert(this.fromdate);

//alert(this.todate);
  this.fromdate=this.myDate
  //this.datePipe.transform(this.range.value.start, 'MM-dd-YYYY'); //this.range.value.start;
  this.todate=this.myDate
  //this.datePipe.transform(this.range.value.end, 'MM-dd-YYYY');
 this.getDataFromAPI(); 
 this.getJobSummaryChartAPI();
 //this.gridApi.setColumnAggFunc('TotalJob', 'sum')
}


//Dropdown Report Type
onOptionsSelected(value:any){ 

}

//Display LocalAuthorities
 getLAFromAPI(){    
  this.service.getLocalauthorities()
  .subscribe(
   data=>{
     this.localAuthorities=data;
   });  
}

//Display LA Volume Chart
getLAVolumeChartAPI(){    
  this.service.getLAVolumeChart(localStorage.getItem('schema'))
  .subscribe(
   data=>{
    let keys = [];
    for(let k in data[0])
    {
        keys.push({quarter:k,routevalue:data[0][k]});
    } 
  // this.rptdata=keys;  
  console.log('keys', keys);
  console.log('rptdata', this.rptdata);
  //console.log('sampl', this.reportdata);
 this.options = {
  title: { text: "Route Category Red,Green,Amber"},
  subtitle: { text: this.latext},
  data:keys,
  series: [
    {
      type: 'column',
      xKey: 'quarter',
      yKeys: ['routevalue'],  
      tooltip: { 
        renderer: function (params:any) { 
          return { content: params.yValue.toFixed(0),
             title: params.xValue     //optional, same as default
       }; } }, 
      label: { formatter: function (params:any) {       
        return params.value === undefined ? '' : params.value.toFixed(0); 
      } }   
    },
  ],
};
}); 

}

//Display Status Summary Chart
getStatusSummaryChartAPI(){    
 
  this.service.getStatusSummaryChartApi(localStorage.getItem('schema'),localStorage.getItem('laid'),this.fromdate,this.todate)
  .subscribe(
   data=>{
    let keys = [];
    console.log('datah',data);
    console.log('data0',data[0]);
    for(let k in data[0])
    {
     // alert('k', k);
        keys.push({
          
          quarter:k,value:data[0][k]});
    } 
  // this.rptdata=keys;  
  console.log('keys', keys);
  console.log('rptdata', this.rptdata);
  //console.log('sampl', this.reportdata);
 this.options = {
  title: { text: "Status Summary"},
  subtitle: { text: this.latext},
  data:keys,
  series: [
    {
      type: 'column',
      xKey: 'quarter',
      yKeys: ['value'], 
      tooltip: { 
        renderer: function (params:any) { 
          return { content: params.yValue.toFixed(0),
             title: params.xValue     //optional, same as default
       }; } }, 
      label: { formatter: function (params:any) { 
        // if the data contains values that are not valid numbers, 
        // the formatter's `value` will be `undefined` 
        return params.value === undefined ? '' : params.value.toFixed(0); 
      } }    
    },
  ],
};
}); 

}

//Display Job Summary Chart
getJobSummaryChartAPI(){    
  this.fromdate=this.datePipe.transform(this.range.value.start, 'dd-MM-YYYY'); //this.range.value.start;
  this.todate=this.datePipe.transform(this.range.value.end, 'dd-MM-YYYY');
  this.service.getJobSummary(localStorage.getItem('schema'),this.fromdate,this.todate)
  .subscribe(
   data=>{
  //  let keys = [];
    console.log('datah',data);
    console.log('data0',data[0]);  
    console.log('data pop',data.pop());
 this.options = {
  title: { text: "Digitiser Audit Chart",fontSize: 18},
  subtitle: { text: this.latext},
  data:data,
  series: [
    {
      type: 'bar',
      xKey: 'Users',
      yKeys:['Assigned', 'InProgress', 'InQC', 'InQA', 'InQuery', 'Completed','QCFailed'],
      yNames: [
        'Assigned', 'InProgress', 'InQC', 'InQA', 'InQuery', 'Completed','QCFailed'], 
        
        axes: [
          {
            type: 'category',
            position: 'left',
            label: {  },
          },
          {
            type: 'number',
            position: 'bottom',
            label: { format: '' },
          },
        ],
        tooltip: { 
          renderer: function (params:any) { 
            return { content: params.yValue.toFixed(0),
               title: params.xValue     //optional, same as default
         }; } },
      label: { formatter: function (params:any) { 
        // if the data contains values that are not valid numbers, 
        // the formatter's `value` will be `undefined` 
        return params.value === undefined ? '' : params.value.toFixed(0); 
      } }    
    },
  ],
};
}); 

}

//Get DigiLive Chart
getDigiLiveChartAPI(){    
  this.service.getDigitiserLiveReportNew(localStorage.getItem('schema'))
  .subscribe(
   data=>{
  //  let keys = [];
    console.log('datah',data);
    console.log('data0',data[0]);  
 this.options = {
  title: { text: "Digitiser Live Chart",fontSize: 18},
  subtitle: { text: this.latext},
  data:data,
  series: [
    {
      type: 'bar',
      xKey: 'Users',
      yKeys:['Assigned', 'InQCToday','InQC','QCFailed'],
      yNames: [
        'Assigned', 'InQCToday','InQC','QCFailed'], 
        
        axes: [
          {
            type: 'category',
            position: 'left',
            label: {  },
          },
          {
            type: 'number',
            position: 'bottom',
            label: { format: '' },
          },
        ],
        tooltip: { 
          renderer: function (params:any) { 
            return { content: params.yValue.toFixed(0),
               title: params.xValue     //optional, same as default
         }; } },
      label: { formatter: function (params:any) { 
        // if the data contains values that are not valid numbers, 
        // the formatter's `value` will be `undefined` 
        return params.value === undefined ? '' : params.value.toFixed(0); 
      } }    
    },
  ],
};
}); 

}
//Display QC Job Summary Chart
getQCJobSummaryChartAPI(){    
  this.service.getQCJobSummaryReport(localStorage.getItem('schema'),this.fromdate,this.todate)
  .subscribe(
   data=>{
  //  let keys = [];
    console.log('datah',data);
    console.log('data0',data[0]);  
 this.options = {
  title: { text: "QC Job Summary",fontSize: 18},
  subtitle: { text: this.latext},
  data:data,
  series: [
    {
      type: 'bar',
      xKey: 'QCUsers',
      yKeys:[ 'QCAssigned','QCInProgress','QCPassed','QCFailed'], 
      yNames: [
        'QCAssigned','QCInProgress', 'QCPassed','QCFailed'], 
        
        axes: [
          {
            type: 'category',
            position: 'left',
            label: {  },
          },
          {
            type: 'number',
            position: 'bottom',
            label: { format: '' },
          },
        ],
        tooltip: { 
          renderer: function (params:any) { 
            return { content: params.yValue.toFixed(0),
               title: params.xValue     //optional, same as default
         }; } },
      label: { formatter: function (params:any) { 
        // if the data contains values that are not valid numbers, 
        // the formatter's `value` will be `undefined` 
        return params.value === undefined ? '' : params.value.toFixed(0); 
      } }    
    },
  ],
};
}); 

}


//Display QA Job Summary Chart
getDigitiserAuditChartAPI(){    
//   this.service.getDigitiserAuditReport(localStorage.getItem('schema'))
//   .subscribe(
//    data=>{
//   //  let keys = [];
//     console.log('datah',data);
//     console.log('data0',data[0]);  
//  this.options = {
//   title: { text: "Digitiser Audit Summary",fontSize: 18},
//   subtitle: { text: this.latext},
//   data:data,
//   series: [
//     {
//       type: 'bar',
//       xKey: 'edited_by',
//       yKeys:['edited_by'], 
//       yNames: ['edited_by'], 
        
//         axes: [
//           {
//             type: 'category',
//             position: 'left',
//             label: {  },
//           },
//           {
//             type: 'number',
//             position: 'bottom',
//             label: { format: '' },
//           },
//         ],
//         tooltip: { 
//           renderer: function (params:any) { 
//             return { content: params.yValue.toFixed(0),
//                title: params.xValue     //optional, same as default
//          }; } },
//       label: { formatter: function (params:any) { 
//         // if the data contains values that are not valid numbers, 
//         // the formatter's `value` will be `undefined` 
//         return params.value === undefined ? '' : params.value.toFixed(0); 
//       } }    
//     },
//   ],
// };
// }); 

// this.service.getDigitiserAuditReport(localStorage.getItem('schema'),this.fromdate,this.todate)
// .subscribe(
//   data=>{
//     let keys = [];
//     for(let k in data[0])
//     {
//         keys.push({quarter:k,routevalue:data[0][k]});
//     } 
//   // this.rptdata=keys;  
//   console.log('keys', keys);
//   console.log('rptdata', this.rptdata);
//   //console.log('sampl', this.reportdata);
//   this.options = {
//   title: { text: ""},
//   subtitle: { text: this.latext},
//   data:keys,
//   series: [
//     {
//       type: 'bar',
//       xKey: 'quarter',
//       yKeys: ['routevalue'],  
//       tooltip: { 
//         renderer: function (params:any) { 
//           return { content: params.yValue.toFixed(0),
//              title: params.xValue     //optional, same as default
//        }; } }, 
//       label: { formatter: function (params:any) {       
//         return params.value === undefined ? '' : params.value.toFixed(0); 
//       } }   
//     },
//   ],
//   };
//   }); 
  
//   this.service.getDigitiserAuditChart(localStorage.getItem('schema'),this.fromdate,this.todate)
//     .subscribe(
//     data=>{
//   this.liverptdate=data.Records[0];
// });

  this.service.getDigitiserAuditChart(localStorage.getItem('schema'),this.fromdate,this.todate)
  .subscribe(
   data=>{
  //  let keys = [];
    //console.log('datah',data);
   console.log('data',data[0]);  

   let keys = [];
   for(let k in data[0])
   {
       keys.push(k);
   } 

   console.log('routevalue', keys); 
   console.log('routevalue1', keys.slice(1)); 
 this.options = {
  title: { text: "Live Report",fontSize: 18},
  subtitle: { text: this.latext},
  data:data,
  series: [
    {
      type: 'bar',
      xKey: 'Users',
      yKeys: keys.slice(1),
      // yNames: [
      //   '[01-03-2022]', '[02-03-2022]', '[03-03-2022]', '[04-03-2022]'], 
         yNames:  keys.slice(1), 
        
        axes: [
          {
            type: 'category',
            position: 'left',
            label: {  },
          },
          {
            type: 'number',
            position: 'bottom',
            label: { format: '' },
          },
        ],
        tooltip: { 
        //  
       },
      label: { formatter: function (params:any) { 
        // if the data contains values that are not valid numbers, 
        // the formatter's `value` will be `undefined` 
        return params.value === undefined ? '' : params.value.toFixed(0); 
      } }    
    },
  ],
};
}); 

}



//Display QA Job Summary Chart
getQAJobSummaryChartAPI(){    
  this.service.getQAJobSummaryReport(localStorage.getItem('schema'),this.fromdate,this.todate)
  .subscribe(
   data=>{
  //  let keys = [];
    console.log('datah',data);
    console.log('data0',data[0]);  
 this.options = {
  title: { text: "QA Job Summary",fontSize: 18},
  subtitle: { text: this.latext},
  data:data,
  series: [
    {
      type: 'bar',
      xKey: 'Users',
      yKeys:['JobAssigned', 'QAInProgress', 'ForQA', 'QAAssigned','QAPassed','QAFailed'], 
      yNames: [
        'JobAssigned', 'QAInProgress', 'ForQA', 'QAAssigned','QAPassed','QAFailed'], 
        
        axes: [
          {
            type: 'category',
            position: 'left',
            label: {  },
          },
          {
            type: 'number',
            position: 'bottom',
            label: { format: '' },
          },
        ],
        tooltip: { 
          renderer: function (params:any) { 
            return { content: params.yValue.toFixed(0),
               title: params.xValue     //optional, same as default
         }; } },
      label: { formatter: function (params:any) { 
        // if the data contains values that are not valid numbers, 
        // the formatter's `value` will be `undefined` 
        return params.value === undefined ? '' : params.value.toFixed(0); 
      } }    
    },
  ],
};
}); 

}

//Display Capture Method Chart
getCaptureMethodChart(){  
 
  this.service.getCaptureMethodApi(localStorage.getItem('schema'),this.fromdate,this.todate)
  .subscribe(
   data=>{
    let keys = [];
     console.log('datah',data);
   
 this.options = {
  title: { text: "Capture Method Summary",fontSize: 18},
  subtitle: { text: this.latext},
  data:data,
  series: [
    {
      type: 'column',
      xKey: 'Capture_method',
      yKeys: ['TotalJob'],  
      tooltip: { 
        renderer: function (params:any) { 
          return { content: params.yValue.toFixed(0),
             title: params.xValue     //optional, same as default
       }; } },
      label: { formatter: function (params:any) { 
        // if the data contains values that are not valid numbers, 
        // the formatter's `value` will be `undefined` 
        return params.value === undefined ? '' : params.value.toFixed(0); 
      } }    
    },
  ],
};
}); 
}

//Mat Tab Group Change code
 _setDataSource(indexNumber:any) {  
  setTimeout(() => {
    switch (indexNumber) {
      case 0:
        this.tabid="Report"; 
        this.isButtonVisible = true;  
        var element = <HTMLInputElement> document.getElementById("btnExcel");
        element.disabled = false;   
        break;
      case 1:
        this.tabid="Chart";
        this.isButtonVisible = false
        var element = <HTMLInputElement> document.getElementById("btnExcel");
        element.disabled = true;
      //  this.getLADataAPI();      
        break;
      case 2:
        this.tabid="qatab";     
        break;
    }
  });
  
}

selectedValue(event: MatSelectChange) {
  this.rptText=event.source.triggerValue;
  // if (event.value=="LA")
  // {
  //   this.show=false;
  // }
  // else
  // {
  //   this.show=true;
  // }
  
  if(this.reporttypeselected=='QRT_Sum' || this.reporttypeselected=='LA' || this.reporttypeselected=='QRT_Out'  || this.reporttypeselected=='DLN'  || this.reporttypeselected=='QC_L' || this.reporttypeselected=='QAL')
  {
    this.isChartExist=false;
  }
  else 
    this.isChartExist=true;
  //alert(event.source.triggerValue);
}


// This function will call specific function based on selected report value
generateReport()
{
  this.fromdate=this.datePipe.transform(this.range.value.start, 'dd-MM-YYYY'); //this.range.value.start;
  this.todate=this.datePipe.transform(this.range.value.end, 'dd-MM-YYYY');
 if( this.fromdate != null)
 {
  
 
 }
 else
 {
  this.myDate = new Date();
  this.myDate = this.datePipe.transform(this.myDate, 'dd-MM-YYYY');
  this.fromdate=this.myDate;
  this.todate=this.myDate;
  }
  //alert(this.fromdate);
 //alert(this.todate);
  
  //this.getLAVolumeChartAPI();
  if(this.reporttypeselected=="JS")//Job Summary Report now changed to Audit Report
  {
      if (this.tabid=="Report")
      {  
        this.getDataFromAPI();
        this.getJobSummaryChartAPI();
       // this.options ={};
      }
      else if (this.tabid=="Chart")
      {
        this.getDataFromAPI();
        this.getJobSummaryChartAPI();
       // this.options ={};
      //alert('hi');
      }
  }
  else if(this.reporttypeselected=="LA") //LA Volume Report
  {
    if (this.tabid=="Report")
    {  
      this.getLAVolumeSummaryReport();
      this.getLAVolumeChartAPI();
    }
    else if (this.tabid=="Chart")
    {
      this.getLAVolumeSummaryReport();
      this.getLAVolumeChartAPI();
    }
  }

  else if(this.reporttypeselected=="SS") //Status Summary Report
  {
    if (this.tabid=="Report")
    {  
      this.getStatusSummaryReport();
      //this.getStatusSummaryChartAPI();
    }
    else if (this.tabid=="Chart")
    {
     // alert('chart');
     // this.getStatusSummaryReport();
      this.getStatusSummaryChartAPI();
    }
  }  
  else if(this.reporttypeselected=="CM") //CaptureMethod Report
  {
    if (this.tabid=="Report")
    {  
      this.getCaptureMethodReport();
      this.getCaptureMethodChart();
    }
    else if (this.tabid=="Chart")
    {
      this.getCaptureMethodReport();
      this.getCaptureMethodChart();
    }
  }

  else if(this.reporttypeselected=="QCJS") //QCJobSummary Report
  {
    if (this.tabid=="Report")
    {  
      this.getQCJobSummaryReport();
      this.getQCJobSummaryChartAPI();
    }
    else if (this.tabid=="Chart")
    {
      this.getQCJobSummaryReport();
      this.getQCJobSummaryChartAPI();
    }
  }

  else if(this.reporttypeselected=="QAJS") //QAJobSummary Report
  {
    if (this.tabid=="Report")
    {  
      this.getQAJobSummaryReport();
      this.getQAJobSummaryChartAPI();
    }
    else if (this.tabid=="Chart")
    {
      this.getQAJobSummaryReport();
      this.getQAJobSummaryChartAPI();
    }
  }

  else if(this.reporttypeselected=="DA") //Live Report Report
  {
  
    //this.fromdate=this.range.value.start;
    //this.todate=this.range.value.end;

   // alert(this.fromdate);
    if (this.tabid=="Report")
    {  
      this.getDigitiserAuditReport();
      this.getDigitiserAuditChartAPI();
    }
    else if (this.tabid=="Chart")
    {
      this.getDigitiserAuditReport();
     this.getDigitiserAuditChartAPI();
    }
  }
  else if(this.reporttypeselected=="DLN") //Live Report Report
  {
  
    //this.fromdate=this.range.value.start;
    //this.todate=this.range.value.end;

   // alert(this.fromdate);
    if (this.tabid=="Report")
    {  
      this.getDigitiserLiveReportNew();
     
    }
    else if (this.tabid=="Chart")
    {
      this.getDigiLiveChartAPI();
    }
  }
  //QC Live Report

  else if(this.reporttypeselected=="QC_L") //Live Report Report
  {
  
    //this.fromdate=this.range.value.start;
    //this.todate=this.range.value.end;

   // alert(this.fromdate);
    if (this.tabid=="Report")
    {  
      this.getQCLiveReport();
     
    }
    else if (this.tabid=="Chart")
    {
      //this.getDigiLiveChartAPI();
    }
  }

  //QA Live Report
  else if(this.reporttypeselected=="QAL") //Live Report Report
  {
  
    //this.fromdate=this.range.value.start;
    //this.todate=this.range.value.end;

   // alert(this.fromdate);
    if (this.tabid=="Report")
    {  
      this.getQALiveReport();
     
    }
    else if (this.tabid=="Chart")
    {
      
    }
  }
  else if(this.reporttypeselected=="QRT_Sum") //LA Volume Report
  {     
      this.getQRTSummary_OutReport('Sum');      
      //this.getQRTSummaryChartAPI();    
  }
  else if(this.reporttypeselected=="QRT_Out") //LA Volume Report
  {     
    this.getQRTSummary_OutReport('Out');
    //this.getQRTOutstandingChartAPI();
  }
}

//Export Excel Function
importExcel()
{
    if (this.tabid=="Report")
    {   
      if(this.reporttypeselected=="JS")
        {   
          let data:any=localStorage.getItem("jobsummary")  
             this.writeExcel(JSON.parse(data),"jobsummary");
        }
        else if(this.reporttypeselected=="LA")
        {   
          let data:any=localStorage.getItem("lavolumesummary")  
          this.writeExcel(JSON.parse(data),"lavolumesummary");           
        } 
        else if(this.reporttypeselected=="SS")
        {   
          let data:any=localStorage.getItem("statussummary")  
          this.writeExcel(JSON.parse(data),"statussummary");            
        }  
        else if(this.reporttypeselected=="CM")
        {   
          let data:any=localStorage.getItem("capturemethod")  
          this.writeExcel(JSON.parse(data),"capturemethod");            
        } 
        else if(this.reporttypeselected=="QCJS")
        {   
          let data:any=localStorage.getItem("QCJobSummary")  
          this.writeExcel(JSON.parse(data),"QCJobSummary");            
        }
        else if(this.reporttypeselected=="QAJS")
        {   
          let data:any=localStorage.getItem("QAJobSummary")  
          this.writeExcel(JSON.parse(data),"QAJobSummary");            
        }
        else if(this.reporttypeselected=="QRT_Sum")
        {   
          let data:any=localStorage.getItem("QRT_Sum")  
          this.writeExcel(JSON.parse(data),"QRT_ChargeSummary");            
        }
        else if(this.reporttypeselected=="QRT_Out")
        {   
          let data:any=localStorage.getItem("QRT_Out")  
          this.writeExcel(JSON.parse(data),"QRT_ChargeOutstanding");            
        }
    }
}
//Function to write excel file
writeExcel(data:any,fname:any)
{
  const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(data)
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, fname); 
  const now = new Date();
  console.log('now',now);
  XLSX.writeFile(wb, fname+now+'.xlsx');
}
//get QRT Charges Summary & Outstanding Report
getQRTSummary_OutReport(report:string){  
   //alert(report);
  // console.log('input',param)
  const laObj= {
    "la_code":''+localStorage.getItem('laid'),
    "la_desc":''+localStorage.getItem('latext')
  }
  this.service.getQRTLAReports(laObj)
  .subscribe(       
   (data:any)=>{   
    if(data==null)
    {
      this.noRowsTemplate =
      '<span style="padding: 10px; border: 2px solid #444; background: #0099a8;">No LA Volume Records </span>';
    }
    else
    {
      //alert(data);
      console.log('QRT Reports',data);
      
      //this.rowData=data;
      if(report=='Sum')
      {
      this.rowData=data[0]; 
      localStorage.setItem('QRT_'+report, JSON.stringify(data[0])); 
      }
      else{
      this.rowData=data[1];
      localStorage.setItem('QRT_'+report, JSON.stringify(data[1])); 
      }
     
    }
    this.columnDefs_Sum = [
     
      {  headerName: "Details",field: 'Details', sortable: true },     
      { headerName: "No.of Charges",field: 'Count', sortable: true, filter: true }
    ];
    this.columnDefs_Out=[
      {  headerName: "Cyient Ref ID",field: 'Details', sortable: true },     
      {  headerName: "Type",field: 'Type', sortable: true },  
      { headerName: "No.of Days",field: 'Count', sortable: true, filter: true }
    ]
    //this.gridApi.setColumnDefs(this.dynamicallyConfigureColumnsFromObject(data[0]));
    if(report=='Sum')          
    {
      this.gridApi.setColumnDefs(
        [ 
          {
        headerName: this.latext,
          children:
        //this.dynamicallyConfigureColumnsFromObject(data[0])
        this.columnDefs_Sum
      
    }]);
    }  else{  
      this.gridApi.setColumnDefs(
        [ 
          {
        headerName: this.latext,
          children:
        //this.dynamicallyConfigureColumnsFromObject(data[0])
        this.columnDefs_Out
      
      }]);   
    }
    console.log('data of QRT LA Reports',data);     
    
    //ladetails.la_code      
   },
   (error:any)=>{
     console.log("error in getQRTSummary_OutReport");
         
   });    

}

//Display QRT Charges Summary & Outstanding chart
getQRTSummaryChartAPI(report:string){
  const laObj= {
  "la_code":''+localStorage.getItem('laid'),
  "la_desc":''+localStorage.getItem('latext')
  }
  this.service.getQRTLAReports(laObj)
  .subscribe(
  data=>{
  let keys = []; let ynames=[];
  let data1;
  if(report=='Sum')
  {
  data1=data[0];
  ynames=['No.of Charges'];
  }
  else
  {
  data1=data[1];
  ynames=['Outstanding Days'];
  }
  console.log('datah',data1);
  console.log('data0',data1[0]);
  for(let k in data1)
  {
  //alert('data1[0].Count:'+data1[0]['count']);
  // let obj={'Details':'','Count':''
  // };
  // obj=k;
  keys.push({
  quarter:data1[k].Count,value:data1[k].Details});
  // keys.push({
  // quarter:data1[k].Details,value:data1[k].Count});
  }
  // this.rptdata=keys;
  console.log('keys', keys);
  console.log('rptdata', this.rptdata);
  //console.log('sampl', this.reportdata);
  this.options = {
  title: { text: "Charges Summary"},
  subtitle: { text: this.latext},
  data:keys,
  series: [
  {
  type: 'bar',
  xKey: 'value',
  yKeys: ['quarter'],
  yNames: ynames,
  // yNames: ['quarter'],
  
  tooltip: {
  renderer: function (params:any) {
  return { content: params.yValue.toFixed(0),
  title: params.xValue //optional, same as default
  }; } },
  label: { formatter: function (params:any) {
  // if the data contains values that are not valid numbers,
  // the formatter's `value` will be `undefined`
  return params.value === undefined ? '' : params.value.toFixed(0);
  } }
  },
  ],
  };
  });
  }

//
}
 