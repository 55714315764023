<mat-card> 
    <mat-card-title>Job Templates</mat-card-title>    
        <mat-card-actions>
            <mat-nav-list style="display: flex;flex-direction: column;width:50%;"> 
                <mat-list-item> 
                   <a href="../../assets/JobsDigitiser.xls" download>  <mat-icon title="Download Job Template" class="white-icon"> import_export</mat-icon>Jobs</a>            
                </mat-list-item>
                    <mat-divider></mat-divider>
                <mat-list-item> 
                    <a href="../../assets/JobsQC.xls" download><mat-icon title="Download QC Template"  class="white-icon"> import_export</mat-icon> QC</a>            
                </mat-list-item>
                <mat-divider></mat-divider>
                <mat-list-item> 
                   <a href="../../assets/JobsQA.xls" download> <mat-icon title="Download QA Template"  class="white-icon"> import_export</mat-icon>QA</a>            
                </mat-list-item>
                <mat-list-item> 
                    <a href="../../assets/JobsTQT.xls" download> <mat-icon title="Download TQT Template"  class="white-icon"> import_export</mat-icon>TQT Jobs</a>            
                 </mat-list-item>
                </mat-nav-list> </mat-card-actions>
</mat-card>

 